import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { LogoWrap } from './styles.jsx';

const getLogo = (logo, theme) => {
  const availableThemes = ['light', 'outline', 'dark'];
  const themeToUse = availableThemes.includes(theme) ? theme : 'dark';
  return getLogoPath(logo, themeToUse);
};
const logoMap = {
  'manufacturing-os': 'manufacturing-os',
  'geomagic-designx': 'geomagic-designx',
  'geomagic-designx-go': 'geomagic-designx-go',
  'geomagic-designx-plus': 'geomagic-designx-plus',
  'geomagic-designx-pro': 'geomagic-designx-pro',
  'geomagic-designx-go-short': 'geomagic-designx-go-short',
  'geomagic-designx-plus-short': 'geomagic-designx-plus-short',
  'geomagic-designx-pro-short': 'geomagic-designx-pro-short',
  'geomagic-controlx': 'geomagic-controlx',
  'geomagic-freeform': 'geomagic-freeform',
  freeform: 'geomagic-freeform',
  'geomagic-wrap': 'geomagic-wrap',
  '3dxpert': '3dxpert',
  amphyon: 'amphyon',
  d2p: 'd2p',
  'geomagic-for-solid': 'G4S'
};

const getLogoPath = (logo, theme) => {
  const logoName = logoMap[logo] || logo;
  return `/assets/logos/${logoName}-${theme}.svg`;
};

const urlMap = {
  'manufacturing-os': 'manufacturingOs',
  'geomagic-designx': 'geoDesignX',
  'geomagic-controlx': 'geoControlX',
  'geomagic-freeform': 'geoFreeeform',
  'geomagic-wrap': 'geoWrap',
  '3dxpert': 'Threedxpert',
  amphyon: 'amphyon',
  d2p: 'd2p',
  'geomagic-for-solid': 'geoDesignX'
};

const getURL = ({ Lang, logo }) => {
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);
  const urlKey = urlMap[logo] || logo;
  return Lang !== 'en' ? globalCopy.Navigation.ProductURLs[urlKey] : `/${logo}/`;
};

export const ProductLogo = ({
  Logo = 'manufacturing-os',
  Theme = 'light',
  Size = 'md',
  Lang = 'en',
  Link = false,
  ...props
}: Props) => {
  const correctedLogo = Logo.replace('geomagic-design-x', 'geomagic-designx')
    .replace('geomagic-designx-products', 'geomagic-designx')
    .replace('geomagic-control-x', 'geomagic-controlx')
    .replace('geomagic-for-solidworks', 'geomagic-for-solid');

  const logoURL = getURL({ Lang, logo: correctedLogo });
  const logoImg = getLogo(correctedLogo, Theme);

  return (
    <LogoWrap className={`Component-ProductLogo size-${Size}`} {...props}>
      {Link ? (
        <a href={logoURL}>
          <img src={logoImg} title={correctedLogo} loading="lazy" alt={correctedLogo} />
        </a>
      ) : (
        <img src={logoImg} title={correctedLogo} loading="lazy" alt={correctedLogo} />
      )}
    </LogoWrap>
  );
};
