import React from 'react';

import GlobalStyle from '../../../../GlobalStyles';

import {
  HighlightBlockStyles,
  Inner,
  ImgWrap,
  CopyWrap,
  ImgWrapSplit,
  BackgroundImage,
  ImgWrapHalfBackground
} from './styles.jsx';
import { ButtonComponent } from '../../Button/Button';
import { createMarkUp } from '../../helpers';

interface Props {
  Title?: string;
  MicroTitle?: string;
  Subtitle?: string;
  Copy?: string;
  Button?: object;
  Image?: object;
  BlockWidth?: 'default' | 'full-width';
  Theme?:
    | 'accent-red'
    | 'accent-red-clear'
    | 'accent-green'
    | '3dxpert'
    | 'Primary'
    | 'geomagicSolidWorks'
    | 'freeform'
    | 'teal'
    | 'teal-dark'
    | 'manufacturing-os'
    | 'dark-teal'
    | 'PrimaryGradient';
  Size?: 'default' | 'extraText';
  ImageType?: 'Separate' | 'Background' | 'Split' | 'HalfBackground';
  AdditionalClass?: string;
}

export const HighlightBlock = ({
  Title = 'Alpha Bravo Charlie',
  MicroTitle = '',
  Subtitle = '',
  Copy = '',
  BlockWidth = 'default',
  AdditionalClass = '',
  Size = 'default',
  ImageType = 'Separate',
  Button = {
    copy: 'Request a free trial',
    link: ''
  },
  Image = {
    className: '',
    url: '',
    title: '',
    alt: ''
  },
  Theme = 'accent-red',
  ...props
}: Props) => {
  const getButtonStyle = Theme => {
    switch (Theme) {
      case 'accent-red':
        return 'white-and-red';
      case 'accent-red-clear':
        return 'clear';
      case 'accent-green-clear':
        return 'clear';
      case 'freeform':
        return 'white-and-teal';
      case 'teal':
        return 'accent-teal';
      case 'teal-dark':
        return 'accent-teal';
      case 'accent-green':
        return 'white-and-green';
      case '3dxpert':
        return '3dxpert-reverse';
      case 'Primary':
        return 'white-and-primary';
      case 'PrimaryGradient':
        return 'white-and-primary';
      case 'manufacturing-os':
        return 'manufacturing-os-reverse';
      case 'geomagicSolidWorks':
        return 'geo-for-solid-reverse';
      case 'dark-teal':
        return 'dark-teal';
      default:
    }
  };

  return (
    <HighlightBlockStyles className={`BlockWidth-${BlockWidth} ${AdditionalClass} theme-${Theme}`} {...props}>
      <div className={'container'}>
        <Inner
          className={`BlockWidth-${BlockWidth} ${AdditionalClass} theme-${Theme} ${
            ImageType === 'Background' ? 'background-image' : ''
          } ${ImageType === 'Split' ? 'flex-center' : ''}`}
          style={ImageType === 'Background' ? { backgroundImage: `url(${Image.url})` } : {}}
        >
          <CopyWrap className={`${AdditionalClass} size-${Size} theme-${Theme} ImageType-${ImageType}`}>
            {MicroTitle && <h5 dangerouslySetInnerHTML={createMarkUp(MicroTitle)}></h5>}
            {Subtitle && <h4 dangerouslySetInnerHTML={createMarkUp(Subtitle)}></h4>}
            {Title && <h3 dangerouslySetInnerHTML={createMarkUp(Title)}></h3>}
            {Copy && <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}

            <ButtonComponent
              ModelTitle={Button.ModelTitle}
              ModelVertical={Button.ModelVertical}
              Target={Button.target}
              Theme={Button.Theme ? Button.Theme : getButtonStyle(Theme)}
              Label={Button.copy}
              Link={Button.link}
              OpenDemoModel={Button.OpenDemoModel}
            />
          </CopyWrap>
          {ImageType === 'Separate' && (
            <ImgWrap className={Image.className + ' size-' + Size}>
              <img loading="lazy" src={Image.url} alt={Image.alt} title={Image.title} />
            </ImgWrap>
          )}
          {ImageType === 'HalfBackground' && (
            <ImgWrapHalfBackground
              className={`bg-image ${AdditionalClass} `}
              style={{ backgroundImage: `url(${Image.url})` }}
            ></ImgWrapHalfBackground>
          )}
          {ImageType === 'Split' && (
            <ImgWrapSplit className={`${AdditionalClass}`}>
              <img loading="lazy" src={Image.url} alt={Image.alt !== undefined ? Image.alt : ''} title={Image.title} />
            </ImgWrapSplit>
          )}
        </Inner>
      </div>
    </HighlightBlockStyles>
  );
};
