import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { colors, spacing, media } from '../../foundations';
import { mediaQueriesSizes } from '../../../GlobalStyles';
import { HeadingM } from '../../../styles/typography';
import { Vertical } from '../../../utils/types';
import { Icon } from '../../subComponents/Icon';
import { FormComponent } from '../../V2/Form/Form';

import { CampaignIDs } from '../../../helpers/campaign-data';

export const OverlayContainer = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(35, 64, 87, 0.5);
`;

export const ContactModalContainer = styled.section`
  padding: 16px;
  border-radius: ${spacing.xs}px;
  background-color: ${colors.primary};
  max-width: 840px;
  width: calc(100vw - 36px);
  max-height: calc(100vh - 36px);
  margin: 16px auto;
  position: relative;
  overflow: auto;
  box-sizing: border-box;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 44px;
  }
`;

export const ModalTitle = styled.h2`
  ${HeadingM};
  padding-right: 40px;
  margin-bottom: ${spacing.l}px;
`;

export const TitleBorder = styled.div`
  width: ${spacing.xxxxl}px;
  border-bottom: 2px solid ${colors.accent};
  margin-bottom: ${spacing.xl}px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  line-height: 32px;
  width: 32px;
  height: 35px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  svg {
    fill: ${colors.secondary};
  }
  &:hover {
    background-color: ${colors.secondaryBackground};
  }
`;

export const getContainerHeight = (vertical?: Vertical) => {
  switch (vertical) {
    default:
      return '850px';
  }
};

export const IframeContainer = styled.div<{ vertical?: Vertical }>`
  height: ${p => getContainerHeight(p.vertical)};
  @media (max-width: ${media.md}px) {
    height: 1250px;
  }
`;

const getFormComponent = (messageValue, productInterestValue, lang = 'en', campaignID) => {
  if (productInterestValue === 'Manufacturing OS') {
    return (
      <FormComponent
        ProductOptions={['Oqton - Additive', 'Oqton - Dental/Healthcare']}
        PrefilledFields={[{ field: 'message', value: messageValue }]}
        HiddenFields={['message']}
        Lang={lang}
        Route={'request-a-trial'}
      />
    );
  }
  return (
    <FormComponent
      PrefilledFields={[
        { field: 'campaign_id', value: campaignID },
        { field: 'message', value: messageValue },
        { field: 'product_interest', value: productInterestValue }
      ]}
      HiddenFields={['message', 'product_interest']}
      Lang={lang}
      Route={'request-a-trial'}
    />
  );
};

export const ContactModal = ({
  handleClose,
  vertical = 'default',
  title = 'Plan your demo',
  lang = 'en',
  iframeURL = undefined
}: {
  handleClose: () => void;
  vertical?: Vertical;
  title?: string;
  iframeURL?: string | undefined;
}) => {
  const location = typeof window !== 'undefined' ? useLocation() : {};
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const renderFormOrIframe = () => {
    if (vertical === 'NewForm-GDX') {
      return getFormComponent(
        'Geomagic Design X Demo Request',
        'Geomagic Design X',
        lang,
        CampaignIDs['geomagic-designx']
      );
    } else if (vertical === 'NewForm-GCX') {
      return getFormComponent(
        'Geomagic Control X Demo Request',
        'Geomagic Control X',
        lang,
        CampaignIDs['geomagic-controlx']
      );
    } else if (vertical === 'NewForm-G4S') {
      return getFormComponent(
        'Geomagic For SOLIDWORKS Demo Request',
        'Geomagic for Solidworks',
        lang,
        CampaignIDs['geomagic-for-solidworks']
      );
    } else if (vertical === 'NewForm-Wrap') {
      return getFormComponent('Geomagic Wrap Demo Request', 'Geomagic Wrap', lang, CampaignIDs['geomagic-wrap']);
    } else if (vertical === 'NewForm-MOS') {
      return getFormComponent('MOS Demo Request', 'Manufacturing OS', lang, {
        additive: CampaignIDs['additive'],
        healthcare: CampaignIDs['healthcare']
      });
    } else if (vertical === 'GeomagicForSolidworks') {
      return getFormComponent(
        'Geomagic For Solidworks Request',
        'Geomagic for Solidworks',
        lang,
        CampaignIDs['geomagic-for-solidworks']
      );
    } else if (vertical === 'NewForm-Freeform') {
      return getFormComponent('Freeform Demo Request', 'Freeform', lang, CampaignIDs['freeform']);
    } else if (vertical === 'NewForm-3dxpert' || vertical === 'NewForm-Product') {
      return getFormComponent('3DXpert Demo Request', '3DXpert', lang, CampaignIDs['3dxpert']);
    } else if (!iframeURL) {
      if (vertical === 'v2-additive-manufacturing') {
        return (
          <FormComponent
            ProductOptions={['Oqton - Additive', 'Oqton - Dental/Healthcare', 'D2P', 'Freeform', '3DXpert']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'additive') {
        return (
          <FormComponent
            ProductOptions={['Oqton - Additive', 'Oqton - Dental/Healthcare', '3DXpert']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === '3d-scanning-solutions') {
        return (
          <FormComponent
            ProductOptions={['Geomagic Design X', 'Geomagic Control X', 'Geomagic Wrap', 'Geomagic for Solidworks']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === '3d-reverse-engineering') {
        return (
          <FormComponent
            ProductOptions={['Geomagic Design X', 'Geomagic Wrap', 'Geomagic for Solidworks']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === '3d-inspection') {
        return (
          <FormComponent
            PrefilledFields={[{ field: 'product_interest', value: 'Geomagic Control X' }]}
            HiddenFields={['message', 'product_interest']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'additive') {
        return (
          <FormComponent
            ProductOptions={['Oqton - Additive', 'Oqton - Dental/Healthcare', '3DXpert']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'NewHealthcareForm') {
        return (
          <FormComponent
            PrefilledFields={[{ field: 'product_interest', value: 'Oqton - Dental/Healthcare' }]}
            HiddenFields={['message', 'product_interest']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'v2-medical') {
        return (
          <FormComponent
            ProductOptions={['Oqton - Dental/Healthcare', 'D2P', 'Freeform', '3DXpert']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'build-quality') {
        return (
          <FormComponent
            ProductOptions={['3DXpert', 'Oqton - Additive', 'Oqton - Dental/Healthcare']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'energy') {
        return (
          <FormComponent
            ProductOptions={['3DXpert', 'Oqton - Additive', 'Geomagic Design X', 'Geomagic Control X']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'amphyon') {
        return <FormComponent HiddenFields={['message']} Lang={lang} Route={'request-a-trial'} />;
      } else if (vertical === 'd2p') {
        return (
          <FormComponent ProductOptions={['D2P']} HiddenFields={['message']} Lang={lang} Route={'request-a-trial'} />
        );
      } else if (vertical === 'aerospace' || vertical === 'automotive') {
        return (
          <FormComponent
            ProductOptions={[
              'Geomagic Design X',
              'Geomagic Control X',
              'Geomagic Wrap',
              'Geomagic for Solidworks',
              '3DXpert',
              'Oqton - Additive'
            ]}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === 'civil') {
        return (
          <FormComponent
            ProductOptions={['Geomagic Design X', 'Geomagic Control X', 'Geomagic Wrap']}
            HiddenFields={['message']}
            Lang={lang}
            Route={'request-a-trial'}
          />
        );
      } else if (vertical === '' || vertical === 'default') {
        return <FormComponent Lang={lang} Route={'request-a-trial'} HiddenFields={['message']} />;
      } else if (vertical === 'HealthcareBrochure') {
        return (
          <IframeContainer vertical={vertical}>
            <iframe
              title=""
              src={'https://go.oqton.com/l/976933/2023-03-07/bd65c'}
              width="100%"
              height="100%"
              allowTransparency
            />
          </IframeContainer>
        );
      }
    } else {
      return (
        <IframeContainer vertical={vertical}>
          <iframe title="" src={iframeURL} width="100%" height="100%" allowTransparency />
        </IframeContainer>
      );
    }
  };

  const ClosePopUp = () => {
    if (typeof window !== 'undefined') {
      navigate(location.pathname, { replace: true });
    }
    handleClose();
  };

  return (
    <OverlayContainer
      id="overlayContainer"
      onClick={e => {
        if ((e.target as HTMLElement).id !== 'overlayContainer') return;
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        ClosePopUp();
      }}
    >
      <ContactModalContainer id="testing">
        <CloseContainer
          onClick={() => {
            ClosePopUp();
          }}
        >
          <Icon name="Cross" />
        </CloseContainer>
        <ModalTitle>{title ? title : 'Get Demo Now'}</ModalTitle>
        <TitleBorder />
        {renderFormOrIframe()}
      </ContactModalContainer>
    </OverlayContainer>
  );
};
