import React from 'react';

import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { BackgroundGrey } from '../../GlobalStyles';
import { HighlightBlockNoImage } from '../../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { TextGrid } from '../../components/V2/TextGrid/TextGrid';
import { WorldMap } from '../../components/V2/WorldMap/WorldMap';

const CompanyPageJSON = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Oqton',
  url: 'https://oqton.com/',
  logo: 'https://oqton.com/assets/images/DarkOqton.svg',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '1-803-981-6716',
      contactType: 'sales',
      areaServed: 'US',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+441442279875',
      contactType: 'sales',
      areaServed: 'GB',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+4961053248122',
      contactType: 'sales',
      areaServed: 'DE',
      availableLanguage: 'German'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+39800035847',
      contactType: 'sales',
      areaServed: 'IT',
      availableLanguage: 'Italian'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+33805103572',
      contactType: 'sales',
      areaServed: 'FR',
      availableLanguage: 'fr'
    }
  ],
  sameAs: [
    'https://twitter.com/oqton',
    'https://www.youtube.com/@Oqton',
    'https://www.linkedin.com/company/oqton/',
    'https://oqton.com/'
  ]
});

const Sirket = () => {
  const Lang = 'tr';
  const data = require(`../../../data/content/Pages/company/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <HelmetContainer currentPage={'company'} lang={Lang} title={data.helmet.title} json={CompanyPageJSON} />
      <PageContainer>
        <Header lang={Lang} />

        <LargeHero
          Background={'/assets/images/pages/partners/partner-bg.webp'}
          BackgroundStyle={'cover'}
          Copy={data.content.Hero.title}
          Button={{
            copy: globalCopy.Navigation.Contact,
            link: globalCopy.Navigation.FooterURLs.contact
          }}
        />

        <SplitColumn
          Copy={data.content.Split.Copy}
          TextSize={'S'}
          Background={'White'}
          ImageOverlap={false}
          ImageBackground={false}
          Image={{
            src: '/assets/images/pages/company/our-vision.png',
            alt: ''
          }}
          Button={{
            theme: 'stripped',
            copy: data.content.Split.Button,
            link: '/posts/how-oqtons-ai-driven-automation-accelerates-manufacturing/'
          }}
        />
        <SplitColumn
          Copy={data.content.Split2.Copy}
          TextSize={'S'}
          ImagePosition={'Left'}
          Background={'White'}
          ImageOverlap={false}
          ImageBackground={false}
          Image={{
            src: '/assets/images/pages/company/autonomous-manufacturing.png',
            alt: ''
          }}
          Button={{
            copy: globalCopy.Buttons.Learn,
            theme: 'stripped',
            link: globalCopy.Navigation.ProductURLs.manufacturingOs
          }}
        />

        <SplitColumn
          Copy={data.content.Split3.Copy}
          TextSize={'S'}
          Background={'White'}
          ImageOverlap={false}
          ImageBackground={false}
          Image={{
            src: '/assets/images/pages/company/Oqton-circle.png',
            alt: ''
          }}
        />

        <BackgroundGrey>
          <TextGrid Title={data.content.Grid.title} Layout={'layout-2'} Items={data.content.Grid.items} />
        </BackgroundGrey>

        <WorldMap
          Title={data.content.WorldMap.title}
          Offices={'11'}
          HomeOffices={'69'}
          HomeOfficeLabels={data.content.WorldMap.HomeOfficeLabel}
          OfficeLabel={data.content.WorldMap.OfficeLabel}
          Copy={data.content.WorldMap.copy}
          Countries={[data.content.WorldMap.locations.line1, data.content.WorldMap.locations.line2]}
        />

        <HighlightBlockNoImage
          Theme={'Dark-Primary'}
          Title={data.content.HighlightBlock.title}
          Button={{
            copy: data.content.HighlightBlock.button,
            link: globalCopy.Navigation.FooterURLs.careers
          }}
        />

        <ArticleBlock Title={data.content.Articles.title} Layout={'layout-3'} MostRecentBlogs={true} />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Sirket;
