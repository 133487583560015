import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGreyReverse, BackgroundBlue } from '../GlobalStyles';
import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { Grid } from '../components/V2/Grid/Grid';

interface Props {
  Lang?: string;
}

const AdditiveManufacturingTemplate = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/additive-manufacturing/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  const vertical = 'v2-additive-manufacturing';

  return (
    <>
      <HelmetContainer currentPage={'additive-manufacturing'} lang={Lang} title={data.helmet.title} />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header demoVertical={vertical} lang={Lang} />

          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'cover'}
            Copy={data.content.Hero.copy}
            Country={Lang}
            Background={'/assets/images/additive-manufacturing/additive-manufacturing-hero.png'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />

          <BackgroundBlue>
            <LogoGridV2
              Title={data.content.Customers.title}
              Logos={[
                {
                  src: '/assets/images/additive-manufacturing/company-logos/addman.png',
                  alt: 'Addman Engineering'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/rafael.png',
                  alt: 'Rafael Advanced Defence Systems LTD'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/baker-hughes.png',
                  alt: 'Baker Hughes'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/emerson.png',
                  alt: 'Emerson'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/3dcusotm-printing.png',
                  alt: '3D Custom Color Printing'
                }
              ]}
              AdditionalClasses={'theme-dark'}
            />
          </BackgroundBlue>

          <TextBlock Title={data.content.TextBlock.title} Copy={data.content.TextBlock.copy} />

          <Grid additionalClasses={'flexible mos'} Items={data.content.Grid.Items} />
        </BackgroundGrey>
        <BackgroundHalfGreyReverse>
          <HighlightBlock
            AdditionalClass={'increased-outer-padding background-white'}
            Theme={'teal'}
            ImageType={'Background'}
            MicroTitle={data.content.HighlightBlock.subtitle}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/additive-manufacturing/cta-bg.png'
            }}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Title={data.content.HighlightBlock.title}
          />
        </BackgroundHalfGreyReverse>

        <ArticleBlock
          Theme={'basic'}
          Title={'Additive Manufacturing Insights'}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
          Link={{
            copy: 'All Insights',
            link: '/news/'
          }}
        />

        <LogoGridV2
          Title={data.content.ExpandableCompanies.title}
          TitleSize={'l'}
          Logos={[
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/3Dsystems.png',
              alt: '3Dsystems'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/EOS.png',
              alt: 'EOS'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/XactMetal.png',
              alt: 'XactMetal'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/slm.png',
              alt: 'SLM'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Stratasys.png',
              alt: 'Stratasys'
            }
          ]}
          LogosGroup2={[
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/HP.png',
              alt: 'HP'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/TRUMPF.png',
              alt: 'TRUMPF'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Renishaw.png',
              alt: 'Renishaw'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Sisma.png',
              alt: 'Sisma'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/NEXA3D.png',
              alt: 'NEXA3D'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Farsoon.png',
              alt: 'Farsoon'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Freemelt.png',
              alt: 'Freemelt'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/DyeMansion.png',
              alt: 'DyeMansion'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/OneLab.png',
              alt: 'OneLab'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/AdditiveIndustries.png',
              alt: 'AdditiveIndustries'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/WeMatter.png',
              alt: 'WeMatter'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Microsoft.png',
              alt: 'Microsoft'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Castor.png',
              alt: 'Castor'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/DigiFabster.png',
              alt: 'DigiFabster'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Hexagon.png',
              alt: 'Hexagon'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/Altair.png',
              alt: 'Altair'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/DMGMori.png',
              alt: 'DMGMori'
            },
            {
              src: '/assets/images/additive-manufacturing/company-logos-2/ImesIcore.png',
              alt: 'ImesIcore'
            }
          ]}
          ExpandButtonText={data.content.ExpandableCompanies.expandButton}
          AdditionalClasses={''}
        />

        <BackgroundGrey>
          <div className={'spacing-md'}></div>
          <HighlightBlock
            AdditionalClass={'background-white text-primary text-limit-width'}
            Theme={'teal-dark'}
            ImageType={'Background'}
            Subtitle={data.content.HighlightBlock2.subtitle}
            Title={data.content.HighlightBlock2.title}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/additive-manufacturing/factory-floor-cta-bg.png'
            }}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
          />

          <div className={'spacing-md'}></div>
        </BackgroundGrey>
      </PageContainer>
      <Footer Lang={Lang} />
    </>
  );
};

export default AdditiveManufacturingTemplate;
