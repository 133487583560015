import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { ProductSpotlight } from '../components/V2/ProductSpotlight/ProductSpotlight';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

interface Props {
  Lang?: string;
}

const MedicalTemplate = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/medical/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  const vertical = 'v2-medical';

  return (
    <>
      <HelmetContainer currentPage={'medical'} lang={Lang} title={data.helmet.title} />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header demoVertical={vertical} lang={Lang} />

          <LargeHero
            Country={Lang}
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={data.content.Hero.copy}
            Background={'/assets/images/medical/medical-hero.png'}
            Button={{
              ModelTitle: globalCopy.Buttons.ContactUs,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
          />
        </BackgroundGrey>
        <AnnoucementBar
          Link={{
            label: 'Know more',
            link: '/posts/digital-workflows-in-orthotics-prosthetics-easier-than-ever-with-geomagic-freeform-2025-0-0/',
            target: '_blank'
          }}
          Content={
            '<h3>Geomagic Freeform - Release 2025</h3><p>Digital workflows in orthotics & prosthetics easier than ever with Geomagic Freeform 2025.0.0. Experience unparalleled ease and efficiency in digital design.</p>'
          }
        />

        <ClientSpotlight Title={data.content.ClientSpotlight.Title} Items={data.content.ClientSpotlight.Items} />

        <TextColumns
          Theme={'dark'}
          Split={'30-70'}
          Title={data.content.TextColumn.Title}
          Copy={data.content.TextColumn.Copy}
        />
        <BackgroundGrey>
          <ProductSpotlight
            Title={data.content.ProductSpotlight.Title}
            Items={data.content.ProductSpotlight.Products}
          />
        </BackgroundGrey>
        <BackgroundHalfGreyReverse>
          <HighlightBlock
            Theme={'Primary'}
            MicroTitle={data.content.HighlightBlock2.micro}
            Title={data.content.HighlightBlock2.title}
            Button={{
              copy: globalCopy.Buttons.ContactUs,
              ModelVertical: vertical,
              ModelTitle: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
            Size={'extraText'}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/medical/dental-mesh.png'
            }}
          />
        </BackgroundHalfGreyReverse>

        <ArticleBlock
          Theme={'basic'}
          Title={data.content.ArticleBlockTitle.title}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'medical'}
          Link={{
            copy: data.content.ArticleBlockTitle.btnCopy,
            link: '/news/'
          }}
        />
      </PageContainer>
      <Footer lang={Lang} />
    </>
  );
};

export default MedicalTemplate;
