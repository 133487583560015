import React, { useState } from 'react';
import { ButtonComponent } from '../../Button/Button';
import styled from 'styled-components';
import { colors, mediaQueriesSizes } from '../../../../GlobalStyles';
import { Modal } from './GridItemHelper';

interface Props {
  Item?: object;
}

const BGImage = styled.div`
  position: absolute;
  width: 100%;
  height: 85%;
  background-position: bottom center;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
`;

const GridItemStyle = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: end;

  h3,
  p,
  a,
  button {
    position: relative;
    z-index: 1;
  }
`;

export const GridItem2 = ({ Item, ...props }: Props) => {
  const handleClick = () => {
    setModalActive(true);
  };

  const [modalActive, setModalActive] = useState(null);

  return (
    <>
      {modalActive ? (
        <Modal
          close={() => {
            setModalActive(false);
          }}
          video={Item.Copy.Video}
        />
      ) : null}
      <GridItemStyle className={'item ' + Item.ComponentSize} {...props}>
        <BGImage style={{ backgroundImage: 'url(' + Item.Copy.BackgroundImage + ')' }} />
        <div>
          <h3>{Item.Copy.Title}</h3>
          <p>{Item.Copy.Copy}</p>
          {Item.Copy.Video && (
            <ButtonComponent ClickFunction={handleClick} Theme={'stripped'} Label={Item.Copy.Button.Label} />
          )}
          {Item.Copy.Button.Link && (
            <ButtonComponent Link={Item.Copy.Button.Link} Theme={'stripped'} Label={Item.Copy.Button.Label} />
          )}
        </div>
      </GridItemStyle>
    </>
  );
};
