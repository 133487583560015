import React from 'react';

import AdditiveTemplate from './../../templates/AdditivePageTemplate';

const Additive = () => {
  const Lang = 'pl';

  return <AdditiveTemplate Lang={Lang} />;
};

export default Additive;
