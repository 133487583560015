import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import {
  faArrowRight,
  faDownload,
  faArrowDown,
  faChevronDown,
  faInfoCircle,
  faChevronUp,
  faPhone,
  faPlay,
  faPlus
} from '@fortawesome/fontawesome-free-solid';
import { ModalContext } from '../../../context/ModalContext';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ButtonStyles } from './styles.jsx';

interface Props {
  ClickFunction?: any;
  OpenDemoModel?: boolean;
  AdditionalClass?: string;
  Label?: string;
  Theme?:
    | 'primary'
    | 'clear'
    | 'tertiary'
    | 'accent-red'
    | 'gradient-red'
    | 'gradient-green'
    | 'gradient-orange'
    | 'accent-teal'
    | 'dark-teal'
    | 'white-and-red'
    | 'white-and-primary'
    | 'white-and-green'
    | 'white-outline'
    | '3dxpert'
    | 'freeform'
    | 'geomagic-freeform'
    | 'geomagic-controlx'
    | 'geomagic-controlx-outline'
    | 'geomagic-designx'
    | 'geomagic-designx-outline'
    | '3dxpert-reverse'
    | 'manufacturing-os'
    | 'geo-for-solid'
    | 'transparent-with-outline'
    | 'transparent-with-white-outline'
    | 'transparent-with-grey-outline'
    | 'stripped-geomagic-designx'
    | 'stripped-geomagic-controlx'
    | 'stripped-geomagic-wrap'
    | 'stripped-geomagic-for-solid'
    | 'stripped';
  Disabled?: boolean;
  Link?: string;
  ID?: string;
  Target?: string;
  ModelTitle?: string;
  IframeURL?: undefined | string;
  ModelVertical?: string;
  Icon?: null | 'arrow-right' | 'arrow-down' | 'chevron-down' | 'chevron-up' | 'play' | 'plus' | 'phone';
  Flag?: null | string;
  IconPosition?: null | 'left' | 'right';
}

fontawesome.library.add(faDownload);
fontawesome.library.add(faArrowRight);
fontawesome.library.add(faArrowDown);
fontawesome.library.add(faPlus);
fontawesome.library.add(faInfoCircle);
fontawesome.library.add(faPhone);

export const ButtonComponent = ({
  AdditionalClass = '',
  ModelTitle = '',
  ID = '',
  ModelVertical = 'default',
  OpenDemoModel = false,
  IframeURL = undefined,
  ClickFunction = false,
  Label = 'Button Label',
  Link = '#',
  Target = '',
  Icon = null,
  Flag = null,
  IconPosition = 'right',
  Disabled = false,
  Theme = 'primary',
  ...props
}: Props) => {
  const { setIsModalOpen } = useContext(ModalContext);
  const { setModalVertical } = useContext(ModalContext);
  const { setModalTitle } = useContext(ModalContext);
  const { setModalIframeURL } = useContext(ModalContext);

  const handleClick = e => {
    e.preventDefault();
    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
    if (setModalVertical) {
      setModalVertical(ModelVertical);
    }
    if (setModalTitle) {
      setModalTitle(ModelTitle);
    }
    if (setModalIframeURL) {
      setModalIframeURL(IframeURL);
    }
  };

  if (OpenDemoModel === null || OpenDemoModel === undefined) {
    OpenDemoModel = false;
  }

  if (IframeURL === null || IframeURL === undefined) {
    IframeURL = undefined;
  }

  if (ClickFunction === null || ClickFunction === undefined) {
    ClickFunction = false;
  }

  return (
    <React.Fragment {...props}>
      {OpenDemoModel && (
        <ButtonStyles
          id={ID}
          onClick={handleClick}
          className={`custom-button theme-${Theme} ${AdditionalClass}`}
          target={Target}
          disabled={Disabled ? true : false}
        >
          <div className={'inner'}>
            {Label}
            {Icon && <FontAwesomeIcon icon={Icon} />}
          </div>
        </ButtonStyles>
      )}

      {ClickFunction && (
        <ButtonStyles
          id={ID}
          onClick={() => {
            ClickFunction();
          }}
          className={`custom-button theme-${Theme} ${AdditionalClass}`}
          target={Target}
          disabled={Disabled ? true : false}
        >
          <div className={'inner icon-pos-' + IconPosition}>
            {IconPosition === 'left' && Icon && <FontAwesomeIcon icon={Icon} />}
            {Label}
            {IconPosition === 'right' && Icon && <FontAwesomeIcon icon={Icon} />}
          </div>
        </ButtonStyles>
      )}

      {!(OpenDemoModel || ClickFunction) && (
        <ButtonStyles
          id={ID}
          href={Link}
          className={`custom-button theme-${Theme} ${AdditionalClass}`}
          target={Target}
          disabled={Disabled}
        >
          <div className={'inner icon-pos-' + IconPosition}>
            {IconPosition === 'left' && Icon && Icon != 'support' && Icon != 'download' && (
              <FontAwesomeIcon icon={Icon} />
            )}
            {Label}
            {IconPosition === 'right' && Icon && Icon != 'support' && Icon != 'download' && (
              <FontAwesomeIcon icon={Icon} />
            )}

            {Icon && Icon === 'support' && <img className={'support'} src={'/assets/icons/support.svg'} />}
            {Icon && Icon === 'download' && <img className={'download'} src={'/assets/icons/download-v2.svg'} />}
            {Flag && <img alt="" className={'flag'} src={`/assets/icons/flags/rectangular/${Flag}.png`} />}
          </div>
        </ButtonStyles>
      )}
    </React.Fragment>
  );
};
