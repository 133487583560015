import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../../components/V2/FeaturedNavigation/FeaturedNavigation';
import { CapterraScore } from '../../components/V2/CapterraScore/CapterraScore';
import { BackgroundGrey, BackgroundHalfGrey } from '../../GlobalStyles';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { FullScreenImage } from '../../components/V2/FullScreenImage/FullScreenImage';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { IconGrid } from '../../components/V2/IconGrid/IconGrid';
import { Grid } from '../../components/V2/Grid/Grid';

const GeomagicDesignX = () => {
  const Lang = 'it';
  const data = require(`../../../data/content/Pages/products/geomagic-controlx/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const sitemap = require(`../../../data/sitemap-data.json`);
  const vertical = 'NewForm-GCX';

  return (
    <>
      <HelmetContainer
        image="/assets/images/pages/geomagicDesignX/key-visual-designx.png"
        currentPage={'geomagic-controlx'}
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <PageContainer lang={Lang}>
        <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

        <LargeHero
          Country={Lang}
          AddSalesTel={true}
          BackgroundStyle={'image'}
          BackgroundColour={'off-white'}
          Logo={'geomagic-controlx'}
          Copy={data.content.hero.copy}
          Background={'/assets/images/pages/geomagic-control-x/wall-e.png'}
          Button={{
            theme: 'gradient-green',
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-controlx-download'][Lang]
          }}
        />

        <BackgroundGrey>
          <FeaturedNavigation
            AdditionalClass={'additional-margin-bottom'}
            Theme={'gradient-green'}
            Title={data.content.FeaturedNavigation.Title}
            NavItems={[
              {
                Title: data.content.FeaturedNavigation.Nav[0],
                Link: '#overview'
              },
              {
                Title: data.content.FeaturedNavigation.Nav[1],
                Link: '#applications'
              },
              {
                Title: data.content.FeaturedNavigation.Nav[2],
                Link: '#highlights'
              }
            ]}
            Copy={data.content.FeaturedNavigation.Copy}
            Image={{
              link: '/assets/images/pages/geomagic-control-x/hero.png',
              alt: data.content.FeaturedNavigation.ImageAlt
            }}
          />
          <CapterraScore
            Reviewer={'Kamil, Industrial Engineer'}
            Review={'“The best tool to inspect hundreds of parts using the automation server.”'}
            Image={'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg'}
          />
          <SplitColumn
            id={'overview'}
            TextColor={'Grey'}
            ImagePosition={'Left'}
            ImageOverlap={false}
            ImageBackground={false}
            BoxShadow={false}
            Copy={data.content.SpltiCol1.Copy}
            Background={'Transparent'}
            Image={{
              src: '/assets/images/pages/geomagic-control-x/scanner.png',
              alt: data.content.SpltiCol1.ImgAlt
            }}
          />
          <SplitColumn
            TextColor={'Grey'}
            ImagePosition={'Right'}
            ImageOverlap={false}
            ImageBackground={false}
            Copy={data.content.SpltiCol2.Copy}
            Background={'Transparent'}
            Image={{
              src: '/assets/images/pages/geomagic-control-x/computer.png',
              alt: data.content.SpltiCol2.ImgAlt
            }}
          />
          <SplitColumn
            TextColor={'Grey'}
            ImagePosition={'Left'}
            ImageOverlap={false}
            ImageBackground={false}
            Copy={data.content.SpltiCol3.Copy}
            BoxShadow={false}
            Background={'Transparent'}
            Image={{
              src: '/assets/images/pages/geomagic-control-x/computer_cloud.png',
              alt: data.content.SpltiCol3.ImgAlt
            }}
          />
          <SplitColumn
            TextColor={'Grey'}
            ImagePosition={'Right'}
            ImageOverlap={false}
            ImageBackground={false}
            Copy={data.content.SpltiCol4.Copy}
            Background={'Transparent'}
            BoxShadow={false}
            Image={{
              src: '/assets/images/pages/geomagic-control-x/silver_metal_object_with_measurements.png',
              alt: data.content.SpltiCol4.ImgAlt
            }}
          />
        </BackgroundGrey>

        <FullScreenImage
          Title={data.content.FullScreenImage.Title}
          Subtitle={data.content.FullScreenImage.Subtitle}
          TextColour={'Green'}
          Background={'/assets/images/components/background-circles.webp'}
          MinHeight={'60vh'}
          Image={{
            alt: data.content.FullScreenImage.ImgAlt,
            url: '/assets/images/pages/geomagic-control-x/streamline_production.png'
          }}
        />

        <BackgroundGrey>
          <IconGrid
            id={'applications'}
            Theme={'light'}
            Title={data.content.IconGrid.Title}
            Subtitle={data.content.IconGrid.Subtitle}
            Items={data.content.IconGrid.Items}
          />

          <Grid
            ID={'highlights'}
            Title={data.content.Grid.Title}
            Subtitle={data.content.Grid.Subtitle}
            Items={data.content.Grid.Items}
          />
        </BackgroundGrey>

        <ArticleBlock
          id={'customer-stories'}
          Title={"Don't take our word for it! Have a look at how successful our customers are:"}
          Layout={'layout-1'}
          Link={{
            copy: 'All Customer Stories',
            link: '/news/?&category=Customer-Story'
          }}
          Items={[
            {
              title: 'Kindig-it Design builds cooler cars faster with Oqton’s Geomagic Control X and Design X',
              link: '/news/customer-stories/kindig-it-design-builds-cars-faster-with-oqton/',
              image: '/assets/images/pages/geomagicDesignX/news/kindig-it-design-with-geomagic.webp',
              category: 'Customer Story',
              readTime: ''
            },
            {
              title: 'Cummins uses Oqton’s Geomagic software',
              link: '/news/customer-stories/cummins-uses-geomagic-to-get-1952-race-car-running-again/',
              image: '/assets/images/pages/geomagicDesignX/news/cummings-uses-oqton-geomagic.webp',
              category: 'Customer Story',
              readTime: ''
            },
            {
              title:
                "Asano adopted Oqton's Geomagic 3D scanning technologies to transform its design and quality assurance processes",
              link: '/news/customer-stories/asano-uses-geomagic-3d-scan-to-drive-innovation/',
              image: '/assets/images/customerStories/asano/IMG-1.png',
              category: 'Customer Story',
              readTime: ''
            }
          ]}
        />

        <BackgroundHalfGrey>
          <HighlightBlock
            Theme={'accent-green'}
            Title={data.content.HighlightBlock.Title}
            Button={{
              copy: globalCopy.Buttons.DownloadFreeTrial,
              link: sitemap['geomagic-controlx-download'][Lang]
            }}
            Image={{
              title: '',
              alt: 'Geomagic Control X Render',
              url: '/assets/images/pages/geomagic-control-x/hero.png'
            }}
          />
        </BackgroundHalfGrey>

        <BackgroundGrey>
          <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
        </BackgroundGrey>

        <SolutionBlock
          Title={data.content.SolutionBlock.Title}
          Theme={'dark'}
          Items={[
            {
              logo: 'geomagic-designx',
              title: data.content.SolutionBlock.Items[0],
              link: globalCopy.Navigation.ProductURLs.geoDesignX
            },
            {
              logo: 'geomagic-wrap',
              title: data.content.SolutionBlock.Items[1],
              link: globalCopy.Navigation.ProductURLs.geoWrap
            },
            {
              logo: 'geomagic-for-solid',
              title: data.content.SolutionBlock.Items[2],
              link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks
            }
          ]}
        />

        <Footer lang={'it'} />
      </PageContainer>
    </>
  );
};

export default GeomagicDesignX;
