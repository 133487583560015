import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponentV2 } from '../../components/V2/MediaComponent';
import { Statistics } from '../../components/layouts/Statistics';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { ListContainer } from '../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { ButtonContainer, WhiteButton } from '../../components/pageComponents/mediaComponentContent/styles';
import { Icon } from '../../components/subComponents/Icon';
import { PageContainer } from '../../components/containers/PageContainer';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { HighlightBlock } from '../../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { SplitColumnExtraText } from '../../components/V2/SplitColumnExtraText/SplitColumn';

const GeomagicControlX = ({ pageContext }) => {
  const Lang = 'en';

  const globalCopy = pageContext.globalData;
  const sitemap = pageContext.sitemap;

  const vertical = 'NewForm-GCX';

  const data = {
    content: {
      hero: {
        copy: '<h1><b>Unlock the power of automated inspection</b></h1><p>Bring industry-leading automated inspection to more people in more places with a feature rich, highly versatile 3D metrology software that enables automated quality control and better throughput.</p>'
      },
      HighlightBlock: {
        Title: '<b>What are you waiting for? Try for yourself now</b>'
      },
      VideoBlock:
        '<h3>Seamlessly automate your quality control workflows</h3><ul><li>Automate time intensive tasks</li>\n<li>Address the growing manufacturing skill gap by preserving learned expertise</li>\n<li>Improve part quality in less time</li>\n<li>Be more agile in your responses to new customer requests</li>\n<li>Accelerate process sustainability, scrap less parts</li></ul>',
      SplitCols: [
        {
          title: '<b>Integrate automated inspection</b> into your high-volume production',
          subtitle: 'Maximize PC utilization to optimize production throughput with the Automation Server',
          copy: '<p>Increase the efficiency of inspection operations with simultaneous and parallel job processing using server and client PCs.</p><ul><li>Use inspection results to inform and optimize downstream processes and devices. </li><li>Combine with Visual Scripting to maximize production throughput. Use the automation server to call a script, execute a workflow and retrieve the data for further processing. </li><li>Send Pass/Fail results to external production control systems to inform other processes in your production environment.</li></ul>',
          video: 'xGTcvUFXnpgJLMM82L5z4h',
          btn: {
            label: 'Download Sample Files',
            url: '/assets/files/brochures/controlX/cx-automation-volumeextract_samplefiles.zip'
          }
        },
        {
          title: '<b>Complex or custom inspection?</b> Script it. Automate it. ',
          subtitle:
            'Control X Visual Scripting brings complex inspection automation workflows to more people, with no programming knowledge required.',
          copy: '<ul><li>Solve complex inspection challenges using simple visual interface.</li><li>Build a complete automated workflow by dragging operations into the visual scripting environment and stringing them together.</li><li>Improve collaboration and operational efficiency by create logical workflow accessible for less experienced users.</li><li>Elimate human error and increase throughput by removing human interaction from important quality assurance checks.</li></ul>',
          video: 'u46vaWpdR32pfCDwwpio2s',
          btn: {
            label: 'Download Sample Files',
            url: '/assets/files/brochures/controlX/cx-automation-replace-ref-data.scrpt.zip'
          }
        },
        {
          title: '<b>Define once.</b> Run forever. ',
          subtitle:
            'Execute a series of inspection jobs without manual intervention. Define your inspection routine once and run as long as the part is in production. ',
          copy: '<ul><li>Simply and quickly compare many measured data files to reference geometry.</li><li>Maximize throughput, optimize computer resourse utlization when less busy.</li><li>Avoid idling computer resource with minute-by-minute intervention and supervision.</li><li>Optimize metrology processes for production runs, free up expertise for value-add tasks.</li><li>Automatically produce a detailed inspection report for each job</li></ul>',
          video: 'sQWqpdBcPeoptakqZ8ne7R'
        },
        {
          title: '<b>Process scan data quickly</b> with no manual intervention. ',
          subtitle:
            'Automatically execute a series of commands to post process scan data ready for interrogation and reporting. ',
          copy: '<ul><li>Process a series of scan data optimization commands for multiple scan files.</li><li>Avoid idling computer resource with minute-by-minute intervention and supervision.</li><li>Share presets with others in your organization to minimize inspection setup time. </li><li>Use the intuitive Scan Process Designer to create straightforward processing workflows that can be used every time you capture data.</li><li>Elimate human error and increase throughput by automating common scan processing steps.</li></ul>',
          img: '/assets/assets/images/pages/geomagicControlX/automation/process.png'
        }
      ]
    }
  };

  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-controlx-automated-inspection'}
        image="/assets/images/share-images/automated-inspection.jpg"
        title="Automated Inspection - Geomagic Controlx 3D Inspection Software"
        pageDescription="Unlock the power of automated inspection | Developed by Oqton"
        description="Unlock the power of automated inspection | Developed by Oqton"
      />

      <PageContainer lang={'en'}>
        <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

        <LargeHero
          Country={Lang}
          AddSalesTel={true}
          BackgroundStyle={'image'}
          BackgroundColour={'off-white'}
          Logo={'geomagic-controlx'}
          Copy={data.content.hero.copy}
          Background={'/assets/assets/images/pages/geomagicControlX/automation/gcx-automated-inspection.png'}
          Button={{
            theme: 'gradient-green',
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-controlx-download'][Lang]
          }}
        />

        <SplitColumn
          Copy={data.content.VideoBlock}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/2rMabnYFDLKtipN8WT8ryJ?'}
          Background={'Green'}
          ImagePosition={'Right'}
        />

        {data.content.SplitCols.map((block, index) => {
          let orientation = 'Left';
          if (index % 2) {
            orientation = 'Right';
          }
          return (
            <SplitColumnExtraText
              key={index}
              ImagePosition={orientation}
              // Button={block.button}
              Copy={`<h2>${block.title}</h2><h5>${block.subtitle}</h5><p>${block.copy}</p>`}
              Video={block.video ? `https://play.vidyard.com/${block.video}?` : undefined}
              Image={{
                src: block.img,
                alt: ''
              }}
              Button={
                block.btn?.label
                  ? {
                      copy: block.btn.label,
                      fixedUrl: block.btn.url
                    }
                  : undefined
              }
            />
          );
        })}

        <HighlightBlock
          Theme={'accent-green'}
          Title={data.content.HighlightBlock.Title}
          Button={{
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-controlx-download'][Lang]
          }}
          Image={{
            title: '',
            alt: 'Geomagic Control X Render',
            url: '/assets/assets/images/pages/geomagicControlX/automation/gcx-key-visual.png'
          }}
        />

        <div className={'spacing-xl'}></div>

        <Footer />
      </PageContainer>
    </>
  );
};

export default GeomagicControlX;
