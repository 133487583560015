import React from 'react';
import EnergyTemplate from './../../templates/EnergyTemplate';

const Energy = () => {
  const Lang = 'fr';
  const wordpressData = require(`../../../data/wordpress-data/energy-fr-v2.json`);

  return <EnergyTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Energy;
