import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { createMarkUp } from '../helpers';
import Map from './Oqton_Locations.png';

import { WorldMapStyles, LocationWrap, StatisticsWrap, Stat } from './styles.jsx';

interface Props {
  Title?: string;
  Copy?: string;
  Offices?: string;
  OfficeLabel?: string;
  HomeOffices?: string;
  HomeOfficeLabels?: string;
  Countries?: object;
}

export const WorldMap = ({
  Title,
  Copy,
  Offices = '11',
  HomeOffices = '69',
  Countries,
  OfficeLabel,
  HomeOfficeLabels,
  ...props
}: Props) => {
  return (
    <WorldMapStyles>
      <div className={'container'}>
        <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
        <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>
        <div className={'img-wrap'}>
          <img src={Map} className={'map'} alt={''} />
          <LocationWrap>
            <StatisticsWrap>
              <Stat>
                <h4>{Offices}</h4>
                <p dangerouslySetInnerHTML={createMarkUp(OfficeLabel)}></p>
              </Stat>
              <Stat>
                <h4>{HomeOffices}</h4>
                <p dangerouslySetInnerHTML={createMarkUp(HomeOfficeLabels)}></p>
              </Stat>
            </StatisticsWrap>
            <hr className={'divider full'} />
            <div className={'desktop-list'}>
              {Countries.map((list, index) => (
                <ul key={index}>
                  {list.map((country, countryIndex) => (
                    <li key={countryIndex}>
                      {country}
                      <span></span>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
            <ul className={'mobile-list'}>
              {Countries.map((list, index) => (
                <>
                  {list.map((country, countryIndex) => (
                    <li key={countryIndex}>
                      {country}
                      <span></span>
                    </li>
                  ))}
                </>
              ))}
            </ul>
          </LocationWrap>
        </div>
      </div>
    </WorldMapStyles>
  );
};
