import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const ComponentArticleBlock = styled.div`
    padding: 32px 0;
    scroll-margin-top: 50px;

    h2 {
        &.text-centered {
            text-align: center;
            margin: 0 auto 32px;
            max-width: none;
        }

        a {
            color: ${colors.highlight};
        }
    }

    p {
        font-size: 14px;
        width: 100%;
        margin-bottom: 32px;
        font-weight: 300;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 60px 0 0;

        h2.text-centered {
            margin: 24px auto 120px;
            font-size: 36px;
        }


        p {
            width: 50%;
            font-size: 18px;
        }

    }

    &.theme-dark {
        background: ${colors.primary};

        h2 {
            color: ${colors.white};
        }
    }

    .article-block-header{
        h2{
            margin-bottom: 32px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                min-width: 92px;
                margin-left: 32px;
            }
            h2{
                margin-bottom: 0;
            }
        }
    }
    
`;

export const ArticleWrap = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-top: 64px;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(3, 1fr);
  }

  .bgImg {
    width: 100%;
    aspect-ratio: 16 / 9;;
    background-position: center center;
    background-size: cover;
    border-radius: 4px;
  }

`;

export const LinkWrap = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    transition: 0.25s;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: ${colors.primary};
    text-decoration-color: rgba(35, 64, 88, 0.2);
    text-underline-offset: 10px;

    &:hover {
      color: ${colors.highlight};
      text-decoration-color: ${colors.highlight};
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 18px;
    }
  }

  &.theme-dark {
    a {
      color: ${colors.white};
      text-decoration-color: rgba(255, 255, 255, 0.2);

      &:hover {
        color: ${colors.highlight};
        text-decoration-color: ${colors.highlight};
      }
    }
  }

`;

export const Article = styled.a`
  background: #F8F7F6;
  text-decoration: none;
  position: relative;
  transition: 0.25s;

  &.theme-dark {
    background: linear-gradient(52.57deg, #142533 -90.51%, rgba(255, 255, 255, 0) 146.87%),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

    .text-wrap {
      h4, h6 {
        color: white;
      }
    }
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0.15);
  }

  .text-wrap {
    padding: 16px;

    h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      color: ${colors.primary};
      margin-bottom: 16px;
    }

    h6 {
      color: ${colors.primary};
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
    }

  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .text-wrap {
      padding: 32px 40px 72px;

      h4 {
        font-size: 24px;
      }

      h6 {
        font-size: 16px;
        position: absolute;
        bottom: 32px;
      }

    }

  }
`

export const ArticleWrapLayout2 = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  padding: 64px 0;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(2, 1fr);

  }

`;

export const ArticleLayout2 = styled.a`
  text-decoration: none;

  .bgImg {
    border-radius: 4px;
    width: 100%;
    height: 150px;
    background-position: center center;
    background-size: cover;
    margin-bottom: 32px;
    background-color: #ededed;
  }

  .text-wrap {
    h4 {
      font-size: 32px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.01em;
      text-align: left;
      color: ${colors.primary};
      margin-bottom: 32px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .bgImg {
      height: 350px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    .bgImg {
      height: 450px;
    }
  }
`;

export const ArticleLayout2Mini = styled.a`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 0;
  text-decoration: none;

  .bgImg {
    width: 100%;
    height: 158px;
    background-position: center center;
    background-size: cover;
    margin-bottom: 32px;
  }

  .text-wrap {
    padding-bottom: 32px;
    position: relative;

    h4 {
      color: ${colors.primary};
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.01em;
    }

    p {
      font-size: 12px;
      line-height: 1.3;
      color: ${colors.primary};
    }

    .category-wrap {
      margin-top: 24px;
      display: block;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .bgImg {
    }

    .text-wrap {
      .category-wrap {
        position: absolute;
        bottom: 38px;
      }
    }

  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    .bgImg {
      height: 208px;
    }
  }


`;

export const ColumnWrap = styled.div``;

export const Category = styled.span`
  font-size: 12px;
  line-height: 1.3;
  color: ${colors.primary};

  span {
    border-radius: 4px;
    padding: 8px 12px;
    background: rgba(35, 64, 87, 0.05);
    margin-right: 16px;
  }
`;

export const ArticleWrapLayout3 = styled.div`
  margin: 32px 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(4, 1fr);

      &.key-1 {
          grid-column: 2 / 4;
          .text-wrap
          h4{
              font-size: 32px;
          }
      }


  }
`;

export const ArticleLayout3 = styled.a`

  text-decoration: none;

  .bgImg {
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 4 / 2.5;
    background-position: center center;
    background-size: cover;
  }

  .text-wrap {
    padding: 32px 0;

    h4 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0.01em;
      color: ${colors.primary};
    }

    .category-wrap {
      display: inline-block;
      margin-top: 32px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    &.key-1 {
      grid-column: 2 / 4;
      .text-wrap
        h4{
          font-size: 32px;
        }
      }
    }
  }
`

export const ArticleWrapLayout1 = styled.div`
  margin: 32px 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }


`;

export const ArticleWrapLayout4 = styled.div`
  margin: 32px 0;

  a.key-1 {
    grid-column: auto !important;
    .text-wrap h4 {
      font-size: 24px !important;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin: 64px 0;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }


`;

export const ArticleLayout1 = styled.a`

  text-decoration: none;

  .bgImg {
    width: 100%;
    aspect-ratio: 4 / 2.5;
    background-position: center center;
    background-size: cover;
  }

  .text-wrap {
    padding: 32px 0;

    h4 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0.01em;
      color: ${colors.primary};
    }

    .category-wrap {
      display: inline-block;
      margin-top: 32px;
    }
  }

`
