import React, { useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import {
  BackgroundBlue,
  BackgroundGrey,
  BackgroundHalfGreyReverse,
  BackgroundWhite,
  BackgroundHalfBlue
} from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { HighlightBlockRightImage } from '../components/V2/HighlightBlocks/HighlightBlockRightImage/HighlightBlockRightImage';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { ProductSpotlight } from '../components/V2/ProductSpotlight/ProductSpotlight';

interface Props {
  Lang?: string;
}

const EnergyTemplate = ({ Lang = 'en', wordpressData = null, ...props }: Props) => {
  let globalCopy = require(`../../data/content/Global/${Lang}.json`);

  console.log(wordpressData);

  const vertical = 'energy';

  if (wordpressData != null) {
    return (
      <>
        <HelmetContainer
          currentPage={'energy'}
          image={wordpressData.acfFields.seo.page_share_image}
          title={wordpressData.acfFields.seo.page_title}
          pageDescription={wordpressData.acfFields.seo.page_meta_description}
          description={wordpressData.acfFields.seo.page_meta_description}
        />

        <PageContainer lang={Lang}>
          <Header demoVertical={vertical} lang={Lang} />
          <LargeHero
            Country={Lang}
            AddSalesTel={wordpressData.acfFields.hero.hero_.add_sales_number}
            BackgroundStyle={'image'}
            Copy={`<h1><b>${wordpressData.acfFields.hero.hero_.title}</b></h1>${wordpressData.acfFields.hero.hero_.copy}`}
            Background={wordpressData.acfFields.hero.hero_.image}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
          />
          <BackgroundHalfBlue>
            <div className={'wrap'}>
              <AnnoucementBar
                Link={{
                  label: 'Read article',
                  link: 'https://energy-oil-gas.com/news/energy-industry-embraces-3d-printing/',
                  target: '_blank'
                }}
                Content={
                  '<h3>Energy Industry Embraces 3D Printing</h3><p>Big players in the oil & gas industry see the potential to reduce costs and optimize part designs with 3D printing, and Oqton software is helping them successfully implement the technology. Learn more about it in the <i>Energy, Oil & Gas Magazine</i>.</p>'
                }
              />

              <div className={'spacing-xl'}></div>
              <div className={'spacing-sm'}></div>
            </div>
          </BackgroundHalfBlue>
          <HighlightBlockRightImage
            AdditionalClass={'decreased-padding'}
            Theme={'dark-teal'}
            ImageType={'Background'}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/energy/energy-outline.png'
            }}
            Title={wordpressData.acfFields.highlight_block_one.title}
            Copy={wordpressData.acfFields.highlight_block_one.copy}
          />
          {wordpressData.acfFields.text_block && (
            <TextBlock
              Title={wordpressData.acfFields.text_block.title}
              Copy={wordpressData.acfFields.text_block.copy}
            />
          )}
          {wordpressData.acfFields.benefits_section && wordpressData.acfFields.benefits_section.title && (
            <IconGrid
              id={'applications'}
              Theme={'light'}
              Title={wordpressData.acfFields.benefits_section.title}
              Items={wordpressData.acfFields.benefits_section.grid_items}
            />
          )}
          {wordpressData.acfFields.second_text_block && wordpressData.acfFields.second_text_block.title && (
            <TextColumns
              Theme={'dark'}
              Split={'30-70'}
              Title={wordpressData.acfFields.second_text_block.title}
              Copy={wordpressData.acfFields.second_text_block.copy}
              Button={{
                theme: 'transparent-with-white-outline',
                copy: wordpressData.acfFields.second_text_block.button_copy,
                link: wordpressData.acfFields.second_text_block.button_link
              }}
            />
          )}
          {wordpressData.acfFields.featured_product_block_one && (
            <ProductSpotlight
              Language={Lang}
              WordpressData={true}
              Title={wordpressData.acfFields.featured_product_block_one.featured_products.title}
              Items={wordpressData.acfFields.featured_product_block_one.featured_products.featured_products}
            />
          )}
          {wordpressData.acfFields.industry_partner_logos && (
            <LogoGridV2
              Subtitle={wordpressData.acfFields.industry_partner_logos.subtitle}
              Title={wordpressData.acfFields.industry_partner_logos.title}
              Copy={wordpressData.acfFields.industry_partner_logos.copy}
              AdditionalClasses={'left-headers'}
              TitleSize="l"
              SubTitleSize="m"
              Logos={wordpressData.acfFields.industry_partner_logos.logos}
            />
          )}
          {wordpressData.acfFields.featured_product_block_two && (
            <HighlightBlock
              ImageType={'HalfBackground'}
              Theme={'PrimaryGradient'}
              Title={wordpressData.acfFields.highlight_block_two.title}
              Button={{
                copy: globalCopy.Buttons.RequestDemo,
                ModelVertical: vertical,
                ModelTitle: globalCopy.Buttons.RequestDemo,
                OpenDemoModel: true
              }}
              Image={{
                title: '',
                alt: '',
                url: '/assets/images/energy/energy.png'
              }}
            />
          )}
          ;
          {wordpressData.acfFields.featured_product_block_two && (
            <ProductSpotlight
              Language={Lang}
              WordpressData={true}
              Title={wordpressData.acfFields.featured_product_block_two.featured_products.featured_products_title}
              Items={wordpressData.acfFields.featured_product_block_two.featured_products.featured_products}
            />
          )}
          <ArticleBlock
            Link={{
              link: '/news/',
              copy: 'All Insights'
            }}
            MostRecentBlogs={true}
            Title={'Energy Insights'}
            Topic={'energy'}
            Layout={'layout-4'}
          />
          {wordpressData.acfFields.highlight_block_three && (
            <HighlightBlock
              AdditionalClass={'energy-turbine'}
              ImageType={'HalfBackground'}
              Theme={'PrimaryGradient'}
              Title={wordpressData.acfFields.highlight_block_three.title}
              Button={{
                copy: globalCopy.Buttons.RequestDemo,
                ModelVertical: vertical,
                ModelTitle: globalCopy.Buttons.RequestDemo,
                OpenDemoModel: true
              }}
              Image={{
                title: '',
                alt: '',
                url: '/assets/images/energy/windmill.png'
              }}
            />
          )}
          <Accordion
            DataType={'wp'}
            Title={wordpressData.acfFields.faqs_title}
            Items={wordpressData.acfFields.questions}
          />
          <Footer lang={Lang} Lang={Lang} />
        </PageContainer>
      </>
    );
  } else {
    return null;
  }
};

export default EnergyTemplate;
