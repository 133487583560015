import React from 'react';

import { Header } from '../../../components/layouts/HeaderComponent';
import { Footer } from '../../../components/layouts/Footer';

import { PageContainer } from '../../../components/containers/PageContainer';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { LargeHero } from '../../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundWhite } from '../../../GlobalStyles';
import { SplitColumn } from '../../../components/V2/SplitColumn/SplitColumn';
import { BasicTextGrid } from '../../../components/V2/BasicTextGrid/BasicTextGrid';
import { FormComponent } from '../../../components/V2/Form/Form';
import { Gallery } from '../../../components/V2/Gallery/Gallery';
import { ProductHighlight } from '../../../components/V2/ProductHighlightBlock/ProductHighlightBlock';
import { ArticleBlock } from '../../../components/V2/ArticleBlock/ArticleBlock';

interface Props {
  Lang?: string;
}

const Polymers = ({ pageContext, Lang = 'it', ...props }: Props) => {
  const globalCopy = pageContext.globalData;

  const data = require(`../../../../data/wordpress-data/polymers-it.json`);
  const pageVertical = 'NewForm-3dxpert';

  const customData = {
    split_col_title:
      'Scopri come eseguire <b>il posizionamento 3D di oltre 450 pezzi in polimeri</b> per la produzione additiva.',
    gallery: {
      title: 'Parti in polimero create con la produzione additiva',
      items: [
        {
          src: '/assets/2024/polymers/3d-nesting.png',
          caption: '<span>Oggetto 1.</span> Posizionamento 3D'
        },
        {
          src: '/assets/2024/polymers/fixture.png',
          caption: '<span>Oggetto 2.</span> Fissaggio'
        },
        {
          src: '/assets/2024/polymers/drone-structure.png',
          caption: '<span>Oggetto 3.</span> Struttura del drone'
        }
      ]
    },
    product_highlight: {
      title: 'Software professionale per la produzione additiva',
      copy: 'Struttura del drone, 3DXpert offre una soluzione integrata per la stampa 3D di parti in metallo e plastica di alta qualità.'
    },
    articleBlock: {
      title: 'Produzione Additiva',
      button: 'Tutte le intuizioni'
    }
  };

  return (
    <>
      <HelmetContainer
        lang={Lang}
        title={data.acfFields.seo.page_title}
        description={data.acfFields.seo.page_meta_description}
      />

      <PageContainer>
        <BackgroundGrey>
          <Header demoVertical={pageVertical} lang={Lang} demoTitle={globalCopy.Buttons.RequestDemo} />

          <LargeHero
            BackgroundStyle={'center-right'}
            Background={data.acfFields.hero_.image}
            Copy={`<h1><b>${data.acfFields.hero_.title}</b></h1>${data.acfFields.hero_.copy}`}
            Country={Lang}
            AddSalesTel={true}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <SplitColumn
          AdditionalClass={'polymers-video'}
          Copy={`<h2>${customData.split_col_title}</h2>`}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/X2UyZM3RLBBgbQaS47WwGY?'}
          Background={'3DXpert-Circles'}
          ImagePosition={'Left'}
        />

        <BackgroundWhite>
          <BasicTextGrid
            Items={data.acfFields.main_section.blocks}
            Title={data.acfFields.main_section.title}
            Copy={data.acfFields.main_section.copy}
          />
        </BackgroundWhite>

        <Gallery Title={customData.gallery.title} Images={customData.gallery.items} />

        <div className={'polymer-form-container'}>
          <div className={'container '}>
            <h3>{data.acfFields.form.form_subtitle}</h3>
            <h2>{data.acfFields.form.form_title}</h2>
            <hr className={'divider teal'} />
            <FormComponent
              Lang={Lang}
              HiddenFields={['message', 'product_interest']}
              PrefilledFields={[{ field: 'product_interest', value: '3DXpert' }]}
            />
          </div>
        </div>

        <ProductHighlight
          Title={customData.product_highlight.title}
          Copy={customData.product_highlight.copy}
          Product={'3dxpert'}
        />

        <ArticleBlock
          Theme={'basic'}
          Title={customData.articleBlock.title}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
          Link={{
            copy: customData.articleBlock.button,
            link: '/news/'
          }}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Polymers;
