import React from 'react';

import { createMarkUp } from '../helpers';
import { FeaturedNavigationStyles, NavigationWrap, MainWrap, ImgWrap, LowerContentWrapper } from './styles.jsx';

interface Props {
  NavItems?: object;
  ID?: string;
  Title?: string;
  Copy?: string;
  Image?: object;
  AdditionalClass?: string;
  Theme?: 'gradient-red' | 'gradient-green' | 'gradient-orange' | '3dxpert' | 'manufacturing-os' | 'freeform';
}

export const FeaturedNavigation = ({
  ID,
  NavItems,
  Title,
  Copy,
  Image,
  AdditionalClass,
  Theme = 'gradient-red',
  ...props
}: Props) => {
  return (
    <FeaturedNavigationStyles
      id={ID}
      className={'Component-FeaturedNavigation Theme-' + Theme + ' ' + AdditionalClass}
      {...props}
    >
      <div className={'container'}>
        {NavItems && (
          <NavigationWrap>
            {NavItems.map((item, index) => {
              if (item.Link) {
                return (
                  <a key={index} href={item.Link}>
                    {item.Title}
                  </a>
                );
              } else {
                const title = item;
                const link = '#' + item.toLowerCase();

                return (
                  <a key={index} href={link}>
                    {title}
                  </a>
                );
              }
            })}
          </NavigationWrap>
        )}

        <LowerContentWrapper>
          <MainWrap className={Image ? 'half-size' : 'full-size'}>
            <h2>{Title}</h2>
            <div className={'copy'} dangerouslySetInnerHTML={createMarkUp(Copy)}></div>
          </MainWrap>

          {Image && (
            <ImgWrap>
              <img src={Image.link} alt={''} />
            </ImgWrap>
          )}
        </LowerContentWrapper>
      </div>
    </FeaturedNavigationStyles>
  );
};
