import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';

import { ButtonComponent } from '../Button/Button';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { TextColumnsStyles } from './styles.jsx';

interface Props {
  Theme?: 'dark' | 'light';
  Title?: string;
  Copy?: string;
  Button?: object;
  Logos?: object;
  Columns?: string;
  Lang?: string;
  AdditionalClasses?: string;
  Split?: '50-50' | '30-70';
}

export const TextColumns = ({
  Split = '50-50',
  Theme = 'light',
  Title,
  Copy,
  AdditionalClasses,
  Button,
  Logos,
  Lang = 'en',
  Columns = '2',
  ...props
}: Props) => {
  return (
    <>
      <TextColumnsStyles className={`Theme-${Theme} ${AdditionalClasses}`} {...props}>
        <div className={'container'}>
          <div className={`grid-wrap column-counter-${Columns} Split-${Split}`}>
            <div className={'TitleWrap'}>
              <h2>{Title}</h2>
              <div className={'title-underline '}></div>
            </div>
            <div className={`CopyWrap Split-${Split}`}>
              <div dangerouslySetInnerHTML={createMarkUp(Copy)}></div>

              <div className={'logo-wrap'}>
                {Logos &&
                  Logos.map((logo, index) => (
                    <div key={index}>
                      <ProductLogo Lang={Lang} Link={true} key={index} Logo={logo} Theme={Theme} />
                    </div>
                  ))}
              </div>

              {Button && (
                <ButtonComponent
                  Target={Button.target}
                  Theme={Button.theme}
                  Label={Button.copy}
                  Link={Button.link}
                  OpenDemoModel={Button.OpenDemoModel}
                />
              )}
            </div>
          </div>
        </div>
      </TextColumnsStyles>
    </>
  );
};
