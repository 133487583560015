import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import styled from 'styled-components';
import { mediaQueriesSizes } from '../../GlobalStyles';
import { HelmetContainer } from '../../components/pageComponents/helmet';

const ImageWrap = styled.img`
  width: auto;
  max-width: 100%;
  margin: 0 auto;

  &.mobile {
    display: block;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: none;
    }
  }

  &.desktop {
    display: none;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: block;
    }
  }
`;

const GeomagicEmea = () => {
  return (
    <>
      <HelmetContainer
        lang={'en'}
        title={'Geomagic AMS Partner Summit 2024 '}
        pageDescription={'Geomagic AMS Partner Summit 2024 Orlando FL USA'}
        description={'Geomagic AMS Partner Summit 2024 Orlando FL USA'}
      />

      <PageContainer>
        <ImageWrap className={'mobile'} src={'/assets/annoucements/ams-partner-summit-2024.webp'} />
        <ImageWrap className={'desktop'} src={'/assets/annoucements/ams-partner-summit-2024-desktop.webp'} />
      </PageContainer>
    </>
  );
};

export default GeomagicEmea;
