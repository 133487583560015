import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../../GlobalStyles';

const ThreeDInspectionSolution = () => {
  const Lang = 'tr';
  const data = require(`../../../data/content/Pages/threed-reverse-engineering/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const vertical = '3d-reverse-engineering';

  return (
    <>
      <HelmetContainer
        currentPage={'3d-reverse-engineering'}
        lang={Lang}
        title={data.helmet.title}
        description={data.helmet.description}
      />
      <PageContainer>
        <BackgroundWave>
          <Header demoVertical={vertical} lang={Lang} />
          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={data.content.Hero.Copy}
            Background={'/assets/images/solutions/3d-reverse-engineering-key-visual.webp'}
            Button={{
              ModelVertical: vertical,
              copy: globalCopy.Navigation.Contact,
              OpenDemoModel: true,
              theme: 'accent-red'
            }}
          />
        </BackgroundWave>

        <TextColumns
          Columns={'1'}
          Copy={data.content.TextCol.Copy}
          Title={data.content.TextCol.Title}
          Button={{
            copy: data.content.TextCol.button_label,
            theme: 'primary',
            link: '/posts/which-reverse-engineering-solution-is-right-for-me/'
          }}
        />

        <SplitColumn
          ImagePosition={'Left'}
          Logo={'geomagic-designx'}
          Copy={data.content.SplitColumn1.Copy}
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-design-x.webp',
            alt: ''
          }}
          Button={{
            copy: data.content.SplitColumn1.button_label,
            theme: 'white-outline',
            link: globalCopy.Navigation.ProductURLs.geoDesignX
          }}
        />
        <SplitColumn
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Logo={'geomagic-wrap'}
          Copy={data.content.SplitColumn2.Copy}
          Background={'White'}
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-wrap.webp',
            alt: ''
          }}
          Button={{
            copy: data.content.SplitColumn2.button_label,
            theme: 'white-outline',
            link: globalCopy.Navigation.ProductURLs.geoWrap
          }}
        />
        <SplitColumn
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Logo={'geomagic-for-solid'}
          Copy={data.content.SplitColumn3.Copy}
          Background={'Off-White'}
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-for-solidworks.webp',
            alt: ''
          }}
          Button={{
            copy: data.content.SplitColumn3.button_label,
            theme: 'white-outline',
            link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks
          }}
        />

        <ResourceLinks
          Title={data.content.ResourceLinks.Title}
          Copy={data.content.ResourceLinks.Copy}
          Button={{
            Label: data.content.ResourceLinks.Button,
            Link: '/news'
          }}
          Items={[
            {
              category: globalCopy.Categories.Blog,
              title: data.content.ResourceLinks.Blog_1,
              link: {
                target: '_blank',
                url: '/posts/design-on-the-fly-examples-of-3d-scanning-software-accelerating-product-development/',
                copy: globalCopy.Buttons.ReadBlog
              }
            },
            {
              category: globalCopy.Categories.Webinar,
              title: data.content.ResourceLinks.Blog_2,
              link: {
                target: '_blank',
                url: 'https://oqton.com/news/webinars/turbine-blade-webinar/',
                copy: globalCopy.Buttons.WatchWebinar
              }
            },
            {
              category: globalCopy.Categories.Blog,
              title: data.content.ResourceLinks.Blog_3,
              link: {
                target: '_blank',
                url: '/posts/a-guide-to-3d-models-in-reverse-engineering/',
                copy: globalCopy.Buttons.ReadBlog
              }
            }
          ]}
        />
        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default ThreeDInspectionSolution;
