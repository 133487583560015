import React from 'react';

import GlobalStyle from '../../../GlobalStyles';

import { HighlightBlockStyles, Inner, ImgWrap, CopyWrap } from './styles.jsx';
import { ButtonComponent } from './../Button/Button';
import { createMarkUp } from '../helpers';

interface Props {
  Title?: string;
  Button?: object;
  Image?: object;
  Theme?: 'accent-red' | 'accent-green' | '3dxpert' | 'freeform' | 'Primary' | 'geomagicSolidWorks' | 'accent-orange';
  Size?: 'default' | 'extraText';
}

export const HighlightBlock = ({
  Title = 'Alpha Bravo Charlie',
  Size = 'default',
  Button = {
    copy: 'Request a free trial',
    link: ''
  },
  Image = {
    className: '',
    url: '',
    title: '',
    alt: ''
  },
  Theme = 'accent-red',
  ...props
}: Props) => {
  const getButtonStyle = Theme => {
    switch (Theme) {
      case 'accent-red':
        return 'white-and-red';
      case 'accent-green':
        return 'white-and-green';
      case '3dxpert':
        return '3dxpert-reverse';
      case 'Primary':
        return 'white-and-primary';
      case 'geomagicSolidWorks':
        return 'geo-for-solid-reverse';
      case 'accent-orange':
        return 'white-and-orange';
      default:
    }
  };

  return (
    <HighlightBlockStyles {...props}>
      <div className={'container'}>
        <Inner className={'theme-' + Theme}>
          <CopyWrap className={'size-' + Size}>
            <h3 dangerouslySetInnerHTML={createMarkUp(Title)}></h3>
            <ButtonComponent
              ModelTitle={Button.ModelTitle}
              ModelVertical={Button.ModelVertical}
              Target={Button.target}
              Theme={getButtonStyle(Theme)}
              Label={Button.copy}
              Link={Button.link}
              OpenDemoModel={Button.OpenDemoModel}
            />
          </CopyWrap>
          <ImgWrap className={Image.className + ' size-' + Size}>
            <img src={Image.url} alt={Image.alt} title={Image.title} />
          </ImgWrap>
        </Inner>
      </div>
    </HighlightBlockStyles>
  );
};
