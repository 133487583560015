import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { FullScreenImageStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Title?: string;
  Subtitle?: string;
  BackgroundColour?: 'primary-gradient' | 'none';
  Background?: '';
  Image?: object;
  TextColour?: 'Red' | 'White' | 'Primary' | 'Teal' | 'Green';
  MinHeight?: '100vh';
  AddDivider?: boolean;
}

export const FullScreenImage = ({
  Title = '',
  Subtitle,
  Background,
  BackgroundColour = 'none',
  Image,
  TextColour,
  MinHeight,
  AddDivider,
  ...props
}: Props) => {
  return (
    <FullScreenImageStyles
      className={`Component-FullScreenImage Background-colour-${BackgroundColour}`}
      {...props}
      style={{
        ...(Background ? { backgroundImage: `url(${Background})` } : {}),
        minHeight: MinHeight
      }}
    >
      <div className={'container'}>
        <h2 className={'text-' + TextColour} dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
        {AddDivider && <hr className={'divider center teal'} />}
        <h4 className={'text-' + TextColour} dangerouslySetInnerHTML={createMarkUp(Subtitle)}></h4>
        <img src={Image.url} alt={Image.alt} />
      </div>
    </FullScreenImageStyles>
  );
};
