import React from 'react';
import ContactTemplate from './../../templates/ContactPageTemplate';

const Contact = () => {
  return (
    <>
      <ContactTemplate lang={'pl'} form={'https://go.oqton.com/l/976933/2022-04-28/223w'} />
    </>
  );
};

export default Contact;
