import React from 'react';
import EnergyTemplate from './../../templates/EnergyTemplate';

const Energia = () => {
  const Lang = 'pl';
  const wordpressData = require(`../../../data/wordpress-data/energy-pl.json`);

  console.log(wordpressData);

  return <EnergyTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Energia;
