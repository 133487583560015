import React from 'react';
import EnergyTemplate from './../../templates/EnergyTemplate';

const Enerji = () => {
  const Lang = 'tr';
  const wordpressData = require(`../../../data/wordpress-data/energy-tr.json`);

  return <EnergyTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Enerji;
