import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { BoxImageGridStyles, GridWrapper, Item } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ButtonComponent } from '../Button/Button';

interface Props {
  id?: string;
  Title?: string;
  Subtitle?: string;
  Theme?: string;
  Items?: object;
}

export const BoxImageGrid = ({ id = '', Title = '', Theme = 'light', Subtitle = '', Items = [], ...props }: Props) => {
  return (
    <BoxImageGridStyles id={id} className={`Component-BoxImageGrid Theme-${Theme}`} {...props}>
      <div className={'container'}>
        {Title && (
          <>
            <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
            <h4 dangerouslySetInnerHTML={createMarkUp(Subtitle)}></h4>
            <hr className={'divider center'} />
          </>
        )}

        <GridWrapper>
          {Items.map((item, index) => (
            <Item key={index} className={`Theme-${Theme}`}>
              <img src={item.Image} alt={item.Title} />
              <h5>{item.Title}</h5>
              <hr className={'divider'} />
              <p>{item.Copy}</p>
              {item.Link && (
                <>
                  <br />
                  <ButtonComponent Link={item.Link.url} Theme={'accent-teal'} Label={item.Link.copy} />
                </>
              )}
            </Item>
          ))}
        </GridWrapper>
      </div>
    </BoxImageGridStyles>
  );
};
