import React, { useEffect, useState } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';
import { TabbedContentStyles, Content, ContentItem, Navigation } from './styles.jsx';

interface Props {
  Title?: string;
  Items?: object;
}

export const TabbedContent = ({ Title = '', Items = [], ...props }: Props) => {
  const [activeItem, setActiveItem] = useState(null);

  const activateItem = itemKey => {
    setActiveItem(activeItem === itemKey ? null : itemKey);
  };

  useEffect(() => {
    if (activeItem === null) {
      setActiveItem(0);
    }
  });

  return (
    <TabbedContentStyles className={'Component-TabbedContent'} {...props}>
      <div className={'container'}>
        {Title && <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>}

        <Navigation>
          {Items.map((item, index) => (
            <li
              key={index}
              className={activeItem === index ? 'active' : ''}
              onClick={() => {
                activateItem(index);
              }}
            >
              {item.indexTitle}
            </li>
          ))}
        </Navigation>

        <Content>
          {Items.map((item, index) => {
            if (activeItem === null || activeItem === index) {
              return (
                <ContentItem key={index}>
                  <div className={'imageContainer'}>{item.img && <img src={item.img} alt={item.title} />}</div>
                  <div className={'textContainer'}>
                    {item.title && <h3 dangerouslySetInnerHTML={createMarkUp(item.title)}></h3>}
                    {item.copy && <p dangerouslySetInnerHTML={createMarkUp(item.copy)}></p>}
                    {item.list && (
                      <ul className={'list-style-' + item.list.listStyle}>
                        {item.list.items.map((li, key) => (
                          <li key={key} dangerouslySetInnerHTML={createMarkUp(li)}></li>
                        ))}
                      </ul>
                    )}
                  </div>
                </ContentItem>
              );
            }
            return null;
          })}
        </Content>
      </div>
    </TabbedContentStyles>
  );
};
