import React from 'react';

import { createMarkUp } from '../helpers';
import GlobalStyle from '../../../GlobalStyles';
import { LogoGridStyles, Grid, Item, Inner, LinkItem } from './styles.jsx';

interface Props {
  Title?: string;
  Copy?: string;
  Logos?: object;
}

export const LogoGrid = ({ Title, Copy, Logos, ...props }: Props) => {
  return (
    <LogoGridStyles {...props}>
      <div className={'container'}>
        <Inner>
          <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
          {Copy && <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}
          <div className="title-underline "></div>
        </Inner>
        <Grid>
          {Logos &&
            Logos.map((item, index) => (
              <React.Fragment key={index}>
                {item.link ? (
                  <LinkItem href={item.link}>
                    <img alt={item.alt} src={item.src} />
                  </LinkItem>
                ) : (
                  <Item>
                    <img alt={item.alt} src={item.src} />
                  </Item>
                )}
              </React.Fragment>
            ))}
        </Grid>
      </div>
    </LogoGridStyles>
  );
};
