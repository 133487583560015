import React from 'react';
import { Features } from '../../components/layouts/FeatureContainer';
import { Footer } from '../../components/layouts/Footer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { Resources } from '../../components/layouts/Resources';
import { HeaderBackground } from '../../components/pageComponents/header';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { SubHeader } from '../../components/layouts/MediaComponent/styles';
import { ListContainerIndented } from '../../components/pageComponents/Styles/style';
import { createMarkUp } from '../../components/V2/helpers';
import { colorsRD } from '../../components/foundations';
import { PageContainer } from '../../components/containers/PageContainer';

const Amphyon = () => {
  const Lang = 'ja';
  const data = require(`../../../data/content/Pages/products/amphyon/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <HelmetContainer
        lang={Lang}
        image="/assets/images/pages/amphyon/key-visual-amphyon.webp"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
        currentPage={'amphyon'}
      />
      <PageContainer lang={Lang}>
        <HeaderBackground>
          <Header lang={Lang} />
          <Hero
            lang={Lang}
            title={data.content.hero.title}
            image={{ src: '/assets/images/pages/amphyon/key-visual-amphyon.webp', alt: 'Amphyon Hero' }}
            subtitle={<HeaderSubtitle icon="Amphyon" text="Amphyon" />}
            buttons={[
              { text: globalCopy.Buttons.RequestFreeTrial, vertical: 'amphyon' }
              // { text: globalCopy.Buttons.ContactUs, vertical: 'amphyon', color: colorsRD.primary }
            ]}
            phone
          />
        </HeaderBackground>

        <MediaComponent
          articles={[
            {
              title: data.content.media.title,
              content: data.content.media.content,
              image: { src: '/assets/images/pages/amphyon/media_1.png', alt: 'Amphyon', imagePos: 'right' }
            }
          ]}
        />
        <MediaComponent
          articles={[
            {
              title: data.content.media2.title,
              content: (
                <>
                  <p dangerouslySetInnerHTML={createMarkUp(data.content.media2.content)}></p>
                  <SubHeader>{data.content.media2.header_1}</SubHeader>
                  <ListContainerIndented>
                    <li>{data.content.media2.li_1}</li>
                  </ListContainerIndented>
                  <SubHeader>{data.content.media2.header_2}</SubHeader>
                  <ListContainerIndented>
                    <li>{data.content.media2.li_2}</li>
                  </ListContainerIndented>
                  <SubHeader>{data.content.media2.header_3}</SubHeader>
                  <ListContainerIndented>
                    <li>{data.content.media2.li_3}</li>
                  </ListContainerIndented>
                  <SubHeader>{data.content.media2.header_4}</SubHeader>
                  <ListContainerIndented>
                    <li>{data.content.media2.li_4}</li>
                  </ListContainerIndented>
                </>
              ),
              image: {
                src: '/assets/images/pages/amphyon/media_2.png',
                alt: 'Placeholder',
                imagePos: 'right'
              }
            }
          ]}
        />

        <Features title={data.content.Features.title} columns="" featureCards={data.content.Features.cards} />
        <Resources
          title="Resources"
          resources={[
            {
              title: data.content.Resources.card_title,
              label: data.content.Resources.brochure,
              link: '/assets/files/brochures/amphyon/Amphyon-General-22V1-EN-Digital.pdf',
              withBackground: true
            }
          ]}
        />
        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Amphyon;
