import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../components/V2/FeaturedNavigation/FeaturedNavigation';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { Grid } from '../components/V2/Grid/Grid';
import { BackgroundGrey, BackgroundHalfDarkBlue, BackgroundHalfGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import { FullScreenImage } from '../components/V2/FullScreenImage/FullScreenImage';

interface Props {
  lang?: string;
}

const MOSTemplate = ({ lang = 'en', ...props }: Props) => {
  const Lang = lang;
  const data = require(`../../data/content/Pages/products/manufacturing-os-v2/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const pageVertical = 'NewForm-MOS';

  return (
    <>
      <HelmetContainer
        image={'/assets/share/mos.jpg'}
        currentPage={'manufacturing-os'}
        lang={Lang}
        title={data.helmet.title}
        description={data.helmet.description}
      />
      <PageContainer lang={Lang}>
        <Header lang={lang} demoVertical={pageVertical} />
        <LargeHero
          AdditionalClasses={'announcement-bar-bottom'}
          Logo={'manufacturing-os'}
          Copy={data.content.LargeHero.copy}
          BackgroundStyle={'image'}
          BackgroundColour={'off-white'}
          Country={Lang}
          AddSalesTel={true}
          Background={'/assets/images/manufacturing-os/MOSKeyVisual.png'}
          Button={{
            ModelTitle: globalCopy.Buttons.RequestDemo,
            ModelVertical: pageVertical,
            theme: 'manufacturing-os',
            copy: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
        />
        {Lang === 'en' && (
          <BackgroundGrey>
            <AnnoucementBar
              Link={{
                label: 'Read article',
                link: 'https://energy-oil-gas.com/news/energy-industry-embraces-3d-printing/',
                target: '_blank'
              }}
              Content={
                '<h3>Energy Industry Embraces 3D Printing</h3><p>Big players in the oil & gas industry see the potential to reduce costs and optimize part designs with 3D printing, and Oqton software is helping them successfully implement the technology. Learn more about it in the <i>Energy, Oil & Gas Magazine</i>.</p>'
              }
            />
          </BackgroundGrey>
        )}
        <BackgroundGrey>
          <FeaturedNavigation
            AdditionalClass={`annoucment-at-top`}
            Theme={'manufacturing-os'}
            Title={data.content.FeaturedNavigation.Title}
            Copy={data.content.FeaturedNavigation.Copy}
          />
          <ClientSpotlight Title={data.content.ClientSpotlight.Title} Items={data.content.ClientSpotlight.Items} />

          <FullScreenImage
            AddDivider={true}
            BackgroundColour={'primary-gradient'}
            TextColour={'White'}
            Title={'Tailored solutions for every phase of your<br/><b>product journey</b>'}
            Image={{
              url: 'https://oqtonadmin.com/wp-content/uploads/2024/12/mos-product-journey-en.png',
              alt: 'MOS Product Journey'
            }}
          />
        </BackgroundGrey>
        <BackgroundGrey>
          <Grid
            MicroTitle={data.content.Grid.Micro}
            ShowDivider={false}
            Copy={data.content.Grid.Copy}
            Title={data.content.Grid.Title}
            additionalClasses={'flexible '}
            Items={data.content.Grid.Items}
            TitleAlignment={'left'}
          />
        </BackgroundGrey>

        <BackgroundHalfGreyReverse>
          <HighlightBlock
            Title={data.content.HighlightBlock.Title}
            ImageType={'Split'}
            Theme={'manufacturing-os'}
            Image={{
              url: '/assets/images/manufacturing-os/mos-in-screen.png'
            }}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
          />
        </BackgroundHalfGreyReverse>

        <IconGrid
          Title={data.content.IconGrid.Title}
          Subtitle={data.content.IconGrid.Subtitle}
          Items={data.content.IconGrid.Items}
        />

        <BackgroundGrey>
          <Grid
            TitleAlignment={'left'}
            MicroTitle={data.content.TextBlock.MicroTitle}
            Copy={data.content.TextBlock.copy}
            Title={data.content.TextBlock.title}
            ShowDivider={false}
            additionalClasses={'flexible mos'}
            Items={data.content.Grid2.Items}
          />
        </BackgroundGrey>

        <ArticleBlock
          Title={'Manufacturing OS: Real-Life Experiences Shared'}
          Layout={'layout-3'}
          MostRecentBlogs={true}
          Topic={'manufacturing-os'}
          Link={{
            copy: 'All Insights',
            link: '/news/'
          }}
        />

        <BackgroundHalfDarkBlue>
          <HighlightBlock
            Title={data.content.HighlightBlock2.Title}
            ImageType={'HalfBackground'}
            Theme={'manufacturing-os'}
            Image={{
              url: '/assets/images/manufacturing-os/grid.png'
            }}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
          />

          <div className={'spacing-xl'}></div>
        </BackgroundHalfDarkBlue>
        <Footer Lang={lang} lang={lang} />
      </PageContainer>
    </>
  );
};

export default MOSTemplate;
