import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../../GlobalStyles';

const ThreeDInspectionSolution = () => {
  const Lang = 'ja';
  const data = require(`../../../data/content/Pages/threed-inspection-software/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const vertical = '3d-inspection';

  return (
    <>
      <HelmetContainer lang={Lang} title={data.helmet.title} description={data.helmet.description} />
      <PageContainer lang={Lang}>
        <BackgroundWave>
          <Header demoVertical={vertical} lang={Lang} />
          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={data.content.Hero.Copy}
            Background={'/assets/images/solutions/inspection-key-visual-v2.webp'}
            Button={{
              ModelVertical: vertical,
              copy: globalCopy.Navigation.Contact,
              OpenDemoModel: true,
              theme: 'accent-red'
            }}
          />
        </BackgroundWave>

        <TextColumns
          Columns={'1'}
          Title={data.content.TextColumns.Title}
          Copy={data.content.TextColumns.Copy}
          Button={{
            copy: data.content.TextColumns.Button,
            theme: 'primary',
            link: 'https://oqton.com/posts/how-does-3d-scanning-accelerate-inspection/'
          }}
        />

        <SplitColumn
          ImagePosition={'Left'}
          Logo={'geomagic-controlx'}
          Copy={data.content.SplitCol.Copy}
          Background={'Off-White'}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/NnrFHQJxKs2PuVb55Nexfq.html'}
          Button={{
            copy: globalCopy.Buttons.Learn,
            theme: 'white-outline',
            link: globalCopy.Navigation.ProductURLs.geoControlX
          }}
        />

        <ResourceLinks
          Title={data.content.Resources.Title}
          Copy={data.content.Resources.Copy}
          Button={{
            Label: data.content.Resources.Link,
            Link: '/news'
          }}
          Items={[
            {
              category: globalCopy.Categories.Whitepaper,
              title: data.content.Resources.Blog_2,
              link: {
                target: '_blank',
                url: '/news/ebooks/is-3d-scanning-right-for-my-inspection-needs/',
                copy: 'Read Whitepaper'
              }
            },
            {
              category: globalCopy.Categories.Blog,
              title: data.content.Resources.Blog_3,
              link: {
                target: '_blank',
                url: '/posts/introduction-to-first-article-inspection/',
                copy: 'Read blog'
              }
            }
          ]}
        />
        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default ThreeDInspectionSolution;
