import styled from 'styled-components';
import { bgGradients, colors, mediaQueriesSizes } from '../../../GlobalStyles';

export const SplitColumnStyles = styled.div`
  padding: 32px 0;

  .row {
    align-items: center;
    margin: 0 -16px;
    justify-content: center;

    .col {
      flex: 1 0 0%;
      padding: 0 16px;
      align-items: center;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: ${colors.UILightSecondary};
  }

  h3 {
    font-size: 16px;
    color: ${colors.teal};
    font-family: centrano2-medium;
    font-weight: 100;
    line-height: 1.3;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-bottom: 22px;
  }
    
  h5 {
    font-size: 1.2em;
    color: ${colors.primary};
    font-family: centrano2-medium;
    font-weight: 100;
    line-height: 1.3;
    letter-spacing: 0.03em;
    margin-bottom: 16px;
  }
    
   hr.divider{
       margin: 30px 0;
   } 

  h2 {
    font-size: 28px;
    color: ${colors.primary};
    font-family: centrano2-medium;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 22px;
    line-height: 1.4;
    letter-spacing: 0.02em;
    font-family: centrano2-medium;
    font-weight: 400;
    margin-bottom: 22px;
  }

  p {
    font-size: 16px;
    font-weight: 100;
    line-height: 1.4;
    font-family: centrano2-book;
    letter-spacing: 0.02em;
  }

  ul{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    color: ${colors.primary};
    margin: 32px 0 0;
    li{
      font-family: centrano2-book;
      list-style: none;
      margin-left: 32px;
      font-size: 16px;
      font-weight: 100;
      line-height: 1.4;
      margin-bottom: 8px;
      ::marker {
        color: ${colors.teal};
        display: none;
      }
      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 12px;
        margin-left: -1.6em;
        margin-top: 5px;
        line-height: inherit;
        background: url(/assets/icons/ellipse.png) 50% 50% no-repeat;
        list-style: none;
        padding-left: 10px;
        background-size: contain;
      }
    }
  }
  
  ul.checkmarks li:before {
    position: absolute;
    content: '';
    width: 1em;
    height: 1em;
    margin-left: -1.5em;
    margin-top: 2px;
    line-height: inherit;
    background: url(/assets/icons/icon-check.svg) 50% 50% no-repeat;
    list-style: none;
    padding-left: 10px;
  }

  ul.checkmarks {
    li {
      padding-top: 5px;
      padding-left: 20px;
      font-weight: 100;
      font-size: 18px;
      font-family: centrano2-book;
    }
  }

  .custom-button {
    margin-top: 32px;
  }

  .image-wrap {
    img {
      margin: 32px auto 0;
      max-width: 80vw;
      display: block;
    }
  }

  &.ImagePosition-Left {
    @media (min-width: ${mediaQueriesSizes.md}px) {
      .row {
        flex-direction: row-reverse;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    padding: 62px 0;

    .image-wrap {
      img {
        max-width: 70vw;
        max-height: 300px;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    h2 {
      font-size: 36px;
    }

    h4 {
      font-size: 24px;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 70px;
    }

    .image-wrap {
      img {
        max-width: 50vw;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 80px 0;

    h3 {
      font-size: 20px;
    }

    h2 {
      font-size: 40px;
    }

    h4 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }

    ul li{
      font-size: 18px;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 70px;
    }

    .image-wrap {
      img {
        max-width: 100%;
        max-height: 450px;
      }
    }
  }
`;
