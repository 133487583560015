import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import styled from 'styled-components';
import { mediaQueriesSizes } from '../../GlobalStyles';
import { HelmetContainer } from '../../components/pageComponents/helmet';

const ImageWrap = styled.img`
  width: auto;
  max-width: 100%;
  margin: 0 auto;

  &.mobile {
    display: block;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: none;
    }
  }

  &.desktop {
    display: none;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: block;
    }
  }
`;

const GeomagicEmea = () => {
  return (
    <>
      <HelmetContainer
        lang={'en'}
        title={'3DXpert Additive User Group Meeting 2024 '}
        pageDescription={'3DXpert Additive User Group Meeting 2024 at Baker Hughes Houston TX USA'}
        description={'3DXpert Additive User Group Meeting 2024 at Baker Hughes Houston TX USA'}
      />

      <PageContainer>
        <ImageWrap className={'mobile'} src={'/assets/annoucements/3dxpert-additive-user-group-2024-mobile.jpg'} />
        <ImageWrap className={'desktop'} src={'/assets/annoucements/3dxpert-additive-user-group-2024.jpg'} />
      </PageContainer>
    </>
  );
};

export default GeomagicEmea;
