import React from 'react';

import { createMarkUp, generateRandomStrong } from '../helpers';
import { ButtonComponent } from '../Button/Button';
import { SplitColumnStyles, ImageWrap } from './styles.jsx';
import ReactPlayer from 'react-player';
import YouTubePlayer from 'react-player/youtube';

interface Props {
  ID?: string;
  Copy?: string;
  ImagePosition?: string;
  Image?: {
    src: string;
    alt: string;
  };
  Video?: string;
  Button?: {
    copy: string;
    fixedUrl: string;
    theme?: string;
    target?: string;
  };
  FootnoteText?: string;
}

export const SplitColumnExtraText: React.FC<Props> = ({
  ID,
  Video,
  Copy,
  Image,
  FootnoteText,
  ImagePosition = 'left',
  Button,
  ...props
}) => {
  const ShowVideo = (videoEmbedString: string) => {
    if (videoEmbedString.includes('youtube')) {
      return (
        <YouTubePlayer
          width="100%"
          height="auto"
          style={{ aspectRatio: '16/9' }}
          url={videoEmbedString}
          controls
          className="YoutubePlayer"
        />
      );
    }

    if (videoEmbedString.includes('vimeo')) {
      return (
        <ReactPlayer
          width="100%"
          url={videoEmbedString}
          controls
          config={{
            playerOptions: {
              autoplay: false,
              muted: true
            }
          }}
        />
      );
    }

    return (
      <iframe
        title={generateRandomStrong(5)}
        width="100%"
        height="auto"
        style={{ aspectRatio: '16/9' }}
        className="vidyard_iframe"
        src={videoEmbedString}
        allowTransparency
        allowFullScreen
      />
    );
  };

  return (
    <SplitColumnStyles id={ID} className={`Component-SplitColumnExtraText ImagePosition-${ImagePosition}`} {...props}>
      <div className="container">
        <div className="row">
          <div className="col copy-wrap">
            {Copy && <div dangerouslySetInnerHTML={createMarkUp(Copy)} />}
            {Button && (
              <ButtonComponent
                Label={Button.copy}
                Link={Button.fixedUrl}
                Theme={Button.theme || 'transparent-with-grey-outline'}
                Target={Button.target || '_self'}
              />
            )}
          </div>
          <div className="col image-wrap">
            {Video && ShowVideo(Video)}
            {Image && <img loading="lazy" src={Image.src} alt={Image.alt} />}
          </div>
        </div>
      </div>
      {FootnoteText && (
        <div className="container">
          <div dangerouslySetInnerHTML={createMarkUp(FootnoteText)} />
        </div>
      )}
    </SplitColumnStyles>
  );
};
