import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ProductSliderStyles, ProductTile, SliderWrap } from './styles.jsx';
import { createMarkUp } from '../helpers';

import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title?: string;
  Theme?: 'dark' | 'light';
  Lang?: string;
  Copy?: string;
  IgnoreProduct?: string;
}

export const ProductSlider = ({ IgnoreProduct, Theme = 'dark', Title, Copy, Lang = 'en', ...props }: Props) => {
  const Content = require(`../../../../data/content/Pages/thank-you/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <ProductSliderStyles className={`theme-${Theme}`} {...props}>
      <div className={'container'}>
        {!Title && <h2 dangerouslySetInnerHTML={createMarkUp(Content.default_content.product_carousel.title)}></h2>}
        {Title && <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>}
        {Copy && <p className="intro" dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}
        {!Copy && (
          <p
            className="intro"
            dangerouslySetInnerHTML={createMarkUp(Content.default_content.product_carousel.copy)}
          ></p>
        )}

        <SliderWrap>
          <Slider {...settings}>
            {Content.products.map((item, index) => {
              if (IgnoreProduct !== item.Component) {
                return (
                  <ProductTile key={index}>
                    <div className={'inner'}>
                      <ProductLogo Logo={item.Component} />
                      <hr />
                      <h3>{item.title}</h3>
                      <p>{item.copy}</p>
                      <ButtonComponent
                        Link={item.fixedURL}
                        Label={globalCopy.Buttons.Learn}
                        Theme={'transparent-with-grey-outline'}
                      />
                    </div>
                  </ProductTile>
                );
              } else {
                return null;
              }
            })}
          </Slider>
        </SliderWrap>
      </div>
    </ProductSliderStyles>
  );
};
