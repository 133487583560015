import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ButtonComponent } from '../Button/Button';

import { ComponentSolutionBlock, LinkBlock } from './styles.jsx';

interface Props {
  Title?: string;
  Copy?: string;
  Button?: object;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const ResourceLinks = ({ Title = '', Copy = '', Items = [], Button = [], Theme = 'light', ...props }: Props) => {
  return (
    <ComponentSolutionBlock className={'Component-ResourceLinks theme-' + Theme} {...props}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col'}>
            <h2>{Title}</h2>
            <p>{Copy}</p>
            {Button.Label && (
              <ButtonComponent Label={Button.Label} Theme={'white-outline'} Icon={'arrow-right'} Link={Button.Link} />
            )}
          </div>
          {Items.map((item, index) => (
            <LinkBlock key={index} href={item.link.url} target={item.link.target} className={'col'}>
              <div className={'inner'}>
                <div className={'wrap'}>
                  <p className={'category'}>{item.category}</p>
                  <p className={'title'}>{item.title}</p>
                  <span></span>
                  <p className={'btn'}>{item.link.copy}</p>
                </div>
              </div>
            </LinkBlock>
          ))}
        </div>
      </div>
    </ComponentSolutionBlock>
  );
};
