import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../GlobalStyles';

export const ClientSpotlightStyles = styled.div`
  margin: 42px 0;

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.01em;
  }

  .title-underline {
    margin: 24px 0;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
      margin: 80px 0;
    h2 {
      font-size: 40px;
    }
  }
`;

export const ItemWrapper = styled.div`
  margin: 64px 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Item = styled.div`
  width: 100%;

  .image-wrap {
    height: 72px;
    display: flex;
    align-items: center;
  }

  img {
    width: auto;
    height: auto;
    max-height: 50px;
    max-width: 150px;
  }

  hr {
    margin: 16px 0;
    border: none;
    background: rgba(35, 34, 87, 0.2);
    height: 1px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .custom-button{
    margin-top: 16px;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 30%;
  }
  .quoted_person {
    font-weight: bold;
  }
`;
