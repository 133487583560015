import React from 'react';
import { Footer } from '../../../components/layouts/Footer';
import { Hero } from '../../../components/layouts/Hero';
import { MediaComponent } from '../../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { Features } from '../../../components/layouts/FeatureContainer';
import { ListContainer } from '../../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../../components/pageComponents/header';
import { Header } from '../../../components/layouts/HeaderComponent';

import { createMarkUp } from '../../../components/V2/helpers';
import { PageContainer } from '../../../components/containers/PageContainer';

const GeomagicDesignXFreeTrial = () => {
  const Lang = 'it';
  const data = require(`../../../../data/content/Pages/products/geomagic-controlx/free-trial/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-controlx-free-trial'}
        lang={Lang}
        image="/assets/images/pages/geomagic-control-x/hero.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <PageContainer lang={Lang}>
        <HeaderBackground>
          <Header lang={Lang} hideNavigation />
          <Hero
            lang={Lang}
            title={data.content.hero.title}
            description={data.content.hero.description}
            subtitle={<HeaderSubtitle icon="ControlX" boldText="Geomagic" text="Control X" />}
            image={{
              src: '/assets/images/pages/geomagic-control-x/hero.png',
              alt: 'Geomagic Control X Hero'
            }}
            buttons={[{ text: globalCopy.Buttons.RequestFreeTrial, vertical: 'controlX' }]}
            vertical="controlX"
          />
        </HeaderBackground>
        <MediaComponent
          decreasedPadding
          articles={[
            {
              title: data.content.Media1.title,
              content: (
                <React.Fragment>
                  <p>{data.content.Media1.content}</p>
                  <br />

                  <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media1.list)}></ListContainer>
                </React.Fragment>
              ),
              image: {
                src: '/assets/images/pages/geomagic-control-x/media_4.png',
                alt: 'Placeholder',
                imagePos: 'left'
              }
            }
          ]}
        />
        <Features
          title={data.content.Features.title}
          columns="1"
          featureCards={[
            {
              heading: data.content.Features.cards['1'].heading,
              description: data.content.Features.cards['1'].description
            },
            {
              heading: data.content.Features.cards['2'].heading,
              description: data.content.Features.cards['2'].description
            },
            {
              heading: data.content.Features.cards['3'].heading,
              description: data.content.Features.cards['3'].description
            },
            {
              heading: data.content.Features.cards['4'].heading,
              description: data.content.Features.cards['4'].description
            },
            {
              heading: data.content.Features.cards['5'].heading,
              description: data.content.Features.cards['5'].description
            },
            {
              heading: data.content.Features.cards['6'].heading,
              description: data.content.Features.cards['6'].description
            },
            {
              heading: data.content.Features.cards['7'].heading,
              description: data.content.Features.cards['7'].description
            }
          ]}
        />
        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default GeomagicDesignXFreeTrial;
