import React, { useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundHalfGreyReverse, BackgroundWhite } from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../components/V2/FeaturedNavigation/FeaturedNavigation';
import { ChildPageLinks } from '../components/V2/ChildPageLinks/ChildPageLinks';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { Grid } from '../components/V2/Grid/Grid';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { BenefitsIcons } from '../components/V2/BenefitsIcons/BenefitsIcons';
import { SplitColumnExtraText } from '../components/V2/SplitColumnExtraText/SplitColumn';
import { References } from '../components/V2/References/References';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
interface Props {
  Lang?: string;
}

const FreeformTemplate = ({ Lang = 'en', wordpressData = null, ...props }: Props) => {
  let globalCopy = require(`../../data/content/Global/${Lang}.json`);
  let freeformHaptic = require(`../../data/content/Pages/freeform-haptic/${Lang}.json`);

  const vertical = 'NewForm-Freeform';

  if (wordpressData != null) {
    console.log(wordpressData.acfFields);
    let ClientData: any[] = [];

    wordpressData.acfFields.client_block.client_testimonials.map((item, key) => {
      let temp = {
        Logo: item.logo ? item.logo : null, // Check if item.logo is not null
        Copy: item.copy,
        Person: item.author
      };
      ClientData.push(temp);
    });

    return (
      <>
        <HelmetContainer
          image={wordpressData.acfFields.seo.page_share_image}
          title={wordpressData.acfFields.seo.page_title}
          pageDescription={wordpressData.acfFields.seo.page_meta_description}
          description={wordpressData.acfFields.seo.page_meta_description}
        />

        <PageContainer lang={Lang}>
          <Header demoVertical={vertical} lang={Lang} />

          <LargeHero
            Logo={'geomagic-freeform'}
            Country={Lang}
            AddSalesTel={wordpressData.acfFields.hero.hero_.add_sales_number}
            BackgroundStyle={'image'}
            Copy={`<h1><b>${wordpressData.acfFields.hero.hero_.title}</b></h1>${wordpressData.acfFields.hero.hero_.copy}`}
            Background={wordpressData.acfFields.hero.hero_.image}
            Button={{
              theme: 'geomagic-freeform',
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
          />

          <FeaturedNavigation
            AdditionalClass={'max-skull annoucment-at-top'}
            Theme={'freeform'}
            Image={{ link: wordpressData.acfFields.introduction_block.image }}
            Title={wordpressData.acfFields.introduction_block.title}
            NavItems={[]}
            Copy={wordpressData.acfFields.introduction_block.copy}
          />
          <BackgroundGrey>
            <ClientSpotlight Title={wordpressData.acfFields.client_block.title} Items={ClientData} />

            <BackgroundWhite>
              <BenefitsIcons
                title={wordpressData.acfFields.benefits_table.title}
                subtitle={wordpressData.acfFields.benefits_table.subtitle}
                icons={wordpressData.acfFields.benefits_table.benefits}
              />
            </BackgroundWhite>
          </BackgroundGrey>

          <HighlightBlock
            BlockWidth={'full-width'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            MicroTitle={wordpressData.acfFields.highlight_block_one.subtitle}
            Theme={'freeform'}
            ImageType={'Split'}
            Title={`<b>${wordpressData.acfFields.highlight_block_one.title}</b>`}
            Image={{
              url: wordpressData.acfFields.highlight_block_one.image
            }}
          />

          {wordpressData.acfFields.text_and_image_columns.map((block, index) => {
            let orientation = 'Right';
            if (index % 2) {
              orientation = 'Left';
            }

            let button = null;
            if (block.button.add_button === true) {
              button = {
                copy: block.button.label,
                fixedUrl: block.button.link,
                theme: 'geomagic-freeform',
                target: '_blank'
              };
            }

            return (
              <SplitColumnExtraText
                key={index}
                ImagePosition={orientation}
                Button={button}
                Copy={`<h2>${block.title}</h2>${block.copy}`}
                Image={{
                  src: block.image,
                  alt: ''
                }}
              />
            );
          })}

          <BackgroundGrey>
            <References copy={wordpressData.acfFields.references} />
          </BackgroundGrey>
          <HighlightBlock
            BlockWidth={'full-width'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            MicroTitle={wordpressData.acfFields.highlight_block_two.subtitle}
            Theme={'freeform'}
            ImageType={'Split'}
            Title={`<b>${wordpressData.acfFields.highlight_block_two.title}</b>`}
            Image={{
              url: wordpressData.acfFields.highlight_block_two.image
            }}
          />

          <ArticleBlock
            Link={{
              link: '/news/',
              copy: 'All Insights'
            }}
            MostRecentBlogs={true}
            Title={wordpressData.acfFields.news_articles.title}
            Topic={'freeform'}
            Layout={'layout-4'}
          />

          <HighlightBlock
            AdditionalClass={'haptic-announcement'}
            Theme={'PrimaryGradient'}
            Button={{
              target: '_blank',
              link: 'https://www.3dsystems.com/haptics',
              copy: globalCopy.Buttons.Learn
            }}
            Title={freeformHaptic.highlight.title}
            ImageType={'Split'}
            Image={{
              url: 'https://oqtonadmin.com/wp-content/uploads/2024/04/haptic-device.png'
            }}
          />
          <div className={'spacing-xl'}></div>

          <Footer lang={Lang} />
        </PageContainer>
      </>
    );
  } else {
    return null;
  }
};

export default FreeformTemplate;
