import React from 'react';

import { Header } from '../../../components/layouts/HeaderComponent';
import { Footer } from '../../../components/layouts/Footer';

import { PageContainer } from '../../../components/containers/PageContainer';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { LargeHero } from '../../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundWhite } from '../../../GlobalStyles';
import { SplitColumn } from '../../../components/V2/SplitColumn/SplitColumn';
import { BasicTextGrid } from '../../../components/V2/BasicTextGrid/BasicTextGrid';
import { FormComponent } from '../../../components/V2/Form/Form';
import { Gallery } from '../../../components/V2/Gallery/Gallery';
import { ProductHighlight } from '../../../components/V2/ProductHighlightBlock/ProductHighlightBlock';
import { ArticleBlock } from '../../../components/V2/ArticleBlock/ArticleBlock';

interface Props {
  Lang?: string;
}

const Polymers = ({ pageContext, Lang = 'ko', ...props }: Props) => {
  const globalCopy = pageContext.globalData;

  const data = require(`../../../../data/wordpress-data/polymers-ko.json`);
  const pageVertical = 'NewForm-3dxpert';

  const customData = {
    split_col_title: '적층 제조를 위해 <b>450개 이상의 폴리머 부품을 3D로 배치하는</b> 방법을 알아보세요.',
    gallery: {
      title: '적층 제조로 생성된 폴리머 부품',
      items: [
        {
          src: '/assets/2024/polymers/3d-nesting.png',
          caption: '<span>객체 1.</span> 3D 배치'
        },
        {
          src: '/assets/2024/polymers/fixture.png',
          caption: '<span>객체 2.</span> 고정 장치'
        },
        {
          src: '/assets/2024/polymers/drone-structure.png',
          caption: '<span>객체 3.</span> 드론 구조'
        }
      ]
    },
    product_highlight: {
      title: '전문 적층 제조 소프트웨어',
      copy: '드론 구조, 3DXpert는 고품질 금속 및 플라스틱 부품의 3D 프린팅을 위한 통합 솔루션을 제공합니다.'
    },
    articleBlock: {
      title: '적층 제조',
      button: '모든 통찰'
    }
  };

  return (
    <>
      <HelmetContainer
        lang={Lang}
        title={data.acfFields.seo.page_title}
        description={data.acfFields.seo.page_meta_description}
      />

      <PageContainer>
        <BackgroundGrey>
          <Header demoVertical={pageVertical} lang={Lang} demoTitle={globalCopy.Buttons.RequestDemo} />

          <LargeHero
            BackgroundStyle={'center-right'}
            Background={data.acfFields.hero_.image}
            Copy={`<h1><b>${data.acfFields.hero_.title}</b></h1>${data.acfFields.hero_.copy}`}
            Country={Lang}
            AddSalesTel={true}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <SplitColumn
          AdditionalClass={'polymers-video'}
          Copy={`<h2>${customData.split_col_title}</h2>`}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/X2UyZM3RLBBgbQaS47WwGY?'}
          Background={'3DXpert-Circles'}
          ImagePosition={'Left'}
        />

        <BackgroundWhite>
          <BasicTextGrid
            Items={data.acfFields.main_section.blocks}
            Title={data.acfFields.main_section.title}
            Copy={data.acfFields.main_section.copy}
          />
        </BackgroundWhite>

        <Gallery Title={customData.gallery.title} Images={customData.gallery.items} />

        <div className={'polymer-form-container'}>
          <div className={'container '}>
            <h3>{data.acfFields.form.form_subtitle}</h3>
            <h2>{data.acfFields.form.form_title}</h2>
            <hr className={'divider teal'} />
            <FormComponent
              Lang={Lang}
              HiddenFields={['message', 'product_interest']}
              PrefilledFields={[{ field: 'product_interest', value: '3DXpert' }]}
            />
          </div>
        </div>

        <ProductHighlight
          Title={customData.product_highlight.title}
          Copy={customData.product_highlight.copy}
          Product={'3dxpert'}
        />

        <ArticleBlock
          Theme={'basic'}
          Title={customData.articleBlock.title}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
          Link={{
            copy: customData.articleBlock.button,
            link: '/news/'
          }}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Polymers;
