import styled from 'styled-components';
import { bgGradients, colors, mediaQueriesSizes } from '../../../GlobalStyles';

export const FooterStyles = styled.div`
  color: white;
  background: linear-gradient(0deg, #142533 0%, #1a3041 100%), #ffffff;
  position: relative;
  z-index: 1;

  .zh-cn-footer {
    text-align: right;
    font-size: 16px;
    margin: 16px auto 8px;
    a {
      color: white;
      font-size: 16px;
      padding: 6px;
    }
  }

  .top-section {
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    hr {
      width: 32px;
      border-color: ${colors.teal};
      opacity: 1;
      margin: 12px 0;
    }

    .col {
      margin-bottom: 64px;
      min-width: 100px;
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        min-width: 120px;
      }
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        min-width: 160px;
      }

      hr {
        height: 3px;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 0.875em;
        line-height: 1.4;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: #ffffff;
      }

      &.products {
        min-width: 100%;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          min-width: 0;
        }
      }
    }

    .wrap {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: ${mediaQueriesSizes.sm}px) {
        flex-direction: row;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        gap: 24px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        gap: 64px;
      }
    }

    ul {
      li {
        height: 30px;
        a {
          text-decoration: none;
          font-weight: 300;
          font-size: 14px;
          line-height: 1.41;
          color: #ffffff;
          padding: 14px 0;
          //padding: 6px 0;
          display: inline-block;
          transition: 0.25s;

          &:hover {
            color: ${colors.teal};
          }
        }
      }
    }
  }

  .lower-section {
    padding-top: 40px;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img.logo {
      width: 150px;
      margin-bottom: 32px;
    }

    ul {
      li {
        display: inline-flex;
        align-items: center;
        color: white;
        font-family: centrano2-book;
        font-size: 12px;
        line-height: 24px;

        &:after {
          content: '|';
          margin: 0 24px;
          color: rgba(255, 255, 255, 0.65);
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        a {
          color: rgba(255, 255, 255, 0.65);
          text-decoration: none;
          transition: 0.25s;

          &:hover {
            color: ${colors.teal};
          }
        }
      }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      flex-direction: row;
      align-items: center;

      img.logo {
        width: 100px;
        margin-bottom: 0;
      }
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      img.logo {
        width: 150px;
      }
    }
  }

  hr {
    margin: 0;
    padding: 0;
    opacity: 0.2;
  }

  &.additional-top-padding {
    padding-top: 50px;
  }
`;
