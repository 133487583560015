import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

const BGColour = `rgba(248, 247, 246, 0.95)`;

export const HeaderPageCover = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 24;
    left: 0;
    top: 0;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        display: none;
    }
`;

export const PopUpWrapStyles = styled.div`
    background: rgba(35, 64, 87, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
`

export const PopUpPhone = styled.div`
    width: 680px;
    max-height: 90vh;
    max-width: calc(100% - 32px);
    background: rgba(248, 247, 246, 0.9);
    /* UI-Light/Borders Secondary */
    border: 1px solid rgba(35, 64, 87, 0.2);
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    overflow-y: scroll;
    overflow-x: hidden;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        overflow-y: hidden;
        overflow-x: hidden;
    }

    section.support {

        padding: 30px;

        @media (min-width: ${mediaQueriesSizes.sm}px) {
            padding: 30px 50px 40px;
            display: flex;
        }


        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 1.25em;
            line-height: 1.3;
            letter-spacing: 0.02em;
            color: ${colors.primary};
            margin-bottom: 8px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: ${colors.UILightSecondary};
            font-family: centrano2-book;
            text-wrap: balance;
        }

        a {
            min-width: 150px;
            display: block;
            margin-top: 16px;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                margin-top: 0;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: end;
            }

            font-size: 1.0625em;
            line-height: 120%;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: ${colors.primary};
            text-decoration-color: ${colors.UILightTertiary};
            text-underline-offset: 6px;
        }

    }

    .main {
        background: #FFFFFF;
        border-radius: 6px 6px 0px 0px;
        padding: 32px;
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            padding: 50px;
        }

        h3 {
            font-weight: 700;
            font-size: 1.25em;
            line-height: 1.3;
            letter-spacing: 0.02em;
            color: ${colors.primary};
            margin-bottom: 12px;
        }

        p {
            font-family: centrano2-book;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 1.3;
            letter-spacing: 0.02em;
            color: ${colors.tertiary}
        }

        .grid {
            margin-top: 32px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 10px;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                grid-template-columns: repeat(2, 1fr);
            }

            a.tel-number {
                padding: 8px 10px;
                display: flex;
                align-items: center;
                text-decoration: none;

                font-family: centrano2-book;
                font-style: normal;
                font-weight: 600;
                font-size: 1em;
                line-height: 1;
                letter-spacing: 0.02em;
                transition: 0.25s;
                border-radius: 6px;

                span {
                    font-weight: 400;
                    text-decoration: underline;
                    margin-left: 10px;
                    color: ${colors.UILightSecondary};
                }

                &:hover {
                    background: ${colors.UILightStackSecondary};
                }

                /* Light Primary */
                color: ${colors.primary};

                img {
                    margin-right: 8px;
                    width: 40px;

                    /* Stack Secondary */
                    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));

                }
            }


        }

    }

    button {
        position: absolute;
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        top: 24px;
        right: 24px;
        background: transparent;
        border: 6px;
        cursor: pointer;
        border-radius: 8px;
        img {
            margin: 0;
            margin-right: 0;
            position: relative;
        }

        &:hover {
            /* UI-Light/Stack Accent */
            background: ${colors.UILightBordersTertiary}
        }

    }

`;
export const PopUpLanguage = styled.div`
    width: 480px;
    max-width: calc(100% - 64px);
    background: rgba(248, 247, 246, 0.9);
    border: 1px solid rgba(35, 64, 87, 0.2);
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
        grid-template-columns: repeat(2, 1fr);
    }

    button {
        position: absolute;
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        top: 24px;
        right: 24px;
        background: transparent;
        border-radius: 6px;
        cursor: pointer;

        img {
            margin: 0;
            margin-right: 0;
            position: relative;
        }

        &:hover {
            /* UI-Light/Stack Accent */
            background: ${colors.UILightStackSecondary}
        }
    }

    a {
        display: flex;
        text-decoration: none;
        align-items: center;
        padding: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 1;
        letter-spacing: 0.02em;
        color: ${colors.primary};
        border-radius: 6px;
        transition: 0.25s;
        position: relative;

        svg {
            position: absolute;
            right: 10px;
            color: ${colors.primary};
            font-size: 0.875em;
        }

        &.checked {
            background: ${colors.UILightStackAccent};
        }

        &:hover {
            /* UI-Light/Stack Accent */
            background: ${colors.UILightStackSecondary}

        }

        img {
            width: 30px;
            margin-right: 6px;
        }
    }

`
export const PopUpInner = styled.div`
`

export const HeaderWrap = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 25;
    backdrop-filter: blur(6px);
    padding: ${(props) => props.headerStyle === 'noBG' ? '32px 0' : 0};;
    background: ${(props) => props.headerStyle === 'noBG' ? 'transparent' : 'rgba(248, 247, 246, 0.95)'};
    border-bottom: ${(props) => props.headerStyle === 'noBG' ? 'none' : "1px solid rgba(35, 64, 87, 0.2)"};
    position: ${(props) => props.headerStyle === 'noBG' ? 'absolute' : "fixed"};

    .container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            gap: 20px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            gap: 10px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            gap: 30px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            gap: 16px;
        }

    }
`;

export const HeaderOffset = styled.div`
    display: block;
    height: 80px;
`;

export const ButtonWrap = styled.div`
    display: flex;
    align-items: center;
`;

export const AdditonalButtons = styled.div`
    display: flex;
    align-items: stretch;
    gap: 10px;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
        gap: 20px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        gap: 8px;


    }
`;

export const DesktopLinks = styled.ul`
    display: none;

    &.open-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background: ${BGColour};
        bottom: unset;
        display: flex;
        border-bottom: solid 1px ${colors.UILightTertiary};
        align-items: center;
        justify-content: center;
        gap: 60px;
        width: 100%;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        gap: 30px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        gap: 44px;
    }
    
    & > li a{
        padding:16px 0;
    }

`;

export const NavWrap = styled.div`
    display: flex;
    gap: 10px;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
        gap: 20px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        gap: 30px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        gap: 64px;
    }
    align-items: center;

    .logo {
        height: 18px;
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            height: 23px;
        }

    }

    li, a {
        color: ${colors.primary};
        line-height: 1;
        letter-spacing: 0.02em;
        text-decoration: none;
        font-size: 0.85em;
        font-family: centrano2-book;

        svg {
            height: 10px;
            font-size: 0.75em;
        }
        

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 0.875em;
            a{
                font-size: 1em;
            }
        }
    }

    li {
        position: relative;
        cursor: pointer;
        padding: 8px 0;
        border-bottom: solid 1px transparent;
        
        &.polymers-link {
            list-style: none;

            &:before {
                display: none;
            }
        }

        ul {
            margin-bottom: none;
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 32px 0;
        }


        &:after {
            content: "";
            display: block;
            background: ${colors.primary};
            width: 0%;
            height: 2px;
            position: absolute;
            top: 100%;
            transition: 0.25s;
        }

        &:hover {
            &:after{
                width: 100%;
            }
        }


        &:hover {
            ul.products {
                display: flex;
            }

            ul.solutions {
                display: block;
            }
        }
    }
`;

export const ProductsMenu = styled.ul`
    display: none;
    //display: flex;
    padding: 22px;
    z-index: -1;
    position: absolute;
    background: ${BGColour};
    backdrop-filter: blur(6px);
    top: calc(100% + 6px);
    width: 530px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    border-radius: 6px;
    border: solid 1px ${colors.border};
    transition-delay: 0.1s;

    li {
        padding: 0;
        display: inline-block;
        width: 100%;
        margin: 0;

        a {
            display: block;
            padding: 8px;
            margin-bottom: 8px;
            border-radius: 6px;

            &:hover {
                background: ${colors.UILightStackSecondary};
            }

            img {
                cursor: pointer;
                transition: 0.25s;
                height: 26px !important;
                padding-right: 12px;
            }

        }

        &:hover {
            border-bottom: solid 0 transparent;
        }
    }

`;

export const SolutionsMenu = styled.ul`
    display: none;
    padding: 40px 20px 20px;
    z-index: -1;
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 300;
    position: absolute;
    outline: solid 6px transparent;
    border-radius: 6px;
    background: ${BGColour};
    backdrop-filter: blur(6px);
    top: calc(100% + 6px);
    width: 300px;
    border: solid 1px ${colors.border};
    transition-delay: 0.1s;

    li {
        padding: 0;

        a {
            transition: 0.25s;
            padding: 10px 20px;
            font-family: centrano2-book;

            &:hover {
                background: ${colors.UILightStackSecondary};
            }
        }

        &:hover {
            border-bottom: solid 0 transparent;
        }
    }

    & > li {
        &:nth-of-type(1) {
            margin-bottom: 32px;
            border-bottom: 1px solid ${colors.UILightBordersSecondary};
        }
    }

    ul {
        margin-top: 10px;
        margin-bottom: 10px;

        li {
            padding: 0;
            padding-left: 16px;
            border-bottom: none;

            a {
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 24px;
                display: inline-block;
                font-family: centrano2-book;
            }

            ul {
                border-bottom: none;
                padding: 0;
                margin-top: 0;
                margin-bottom: 0;
            }

            &:before {
                content: '|';
                position: absolute;
                top: 12px;
                left: 25px;
                color: ${colors.UILightBordersSecondary};

            }

            &:hover {
                border-bottom: none;
            }
        }
    }

`;
export const IndustriesMenuStyles = styled.ul`
    display: none;
    //display: block;
    padding: 20px;
    z-index: -1;
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 300;
    position: absolute;
    background: ${BGColour};
    backdrop-filter: blur(6px);
    top: calc(100% + 6px);
    border-radius: 6px;
    width: 210px;
    border: solid 1px ${colors.border};

    li {
        border-bottom: solid 0 transparent;
        font-family: centrano2-book;
        padding: 0;
        margin: 0 0 10px;

        a {
            transition: 0.25s;
            display: block;
            font-family: centrano2-book;
            padding: 6px 20px;
            border-radius: 6px;

            &:hover {
                background: ${colors.UILightStackSecondary};
            }
        }

        &:hover {
            border-bottom: solid 0 transparent;
        }
    }
`;

export const TabletSolutionsMenuWrapper = styled.div`
    border-top: solid 1px ${colors.UILightBordersTertiary};
    display: none;
    overflow: hidden;
    height: 0;
    transition: 0.25s;
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
        height: 230px;

        &.open-menu {
            display: block;
        }
    }

    .container {
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 30px;
        display: flex;
        justify-content: left;
    }

    .divider {
        background: ${colors.UILightBordersTertiary};
        width: 1px;
    }

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 0.75em;
        line-height: 1.41;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        /* UI-Light/Tertiary */
        color: ${colors.UILightTertiary};
        margin-bottom: 12px;
    }

    ul {
        padding: 0;
    }

    li {
        border-bottom: solid 0 transparent;
        text-decoration: none;

        a {
            transition: 0.25s;
            text-decoration: none;
            padding: 10px 0;
            display: block;
            color: ${colors.primary};
            font-family: centrano2-book;
            font-size: 0.875em;
            position: relative;
            
            &:before{
                content: "";
                display: block;
                background: ${colors.UILightStackSecondary};
                width: calc(100% + 16px);
                height: 100%;
                border-radius: 6px;
                position: absolute;
                z-index: -1;
                top:0;
                left: -8px;
                opacity: 0;
                transition: 0.25s;
            }

            &:hover {
                &:before{
                    opacity: 1;
                }
            }
        }

        &:hover {
            border-bottom: solid 0 transparent;
        }

        ul {
            padding: 0;
            width: 100%;
            position: relative;

            li {
                padding: 0;
                padding-left: 16px;
                border-bottom: none;
                position: relative;

                a {
                    display: inline-block;
                    text-decoration: none;
                }

                &:before {
                    content: '';
                    width: 1px;
                    height: 14px;
                    background: ${colors.UILightTertiary};
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 12px;
                }

                &:hover {
                    border-bottom: none;
                }
            }
        }
    }
`;

export const TabletIndustriesMenuWrapper = styled.div`
    border-top: solid 1px ${colors.UILightBordersTertiary};
    display: none;
    height: 230px;
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
        &.open-menu {
            display: block;
        }
    }

    .container {
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 50px;
        display: flex;
        justify-content: flex-start;
    }

    .divider {
        background: ${colors.UILightBordersTertiary};
        width: 1px;
    }

    ul {
        padding: 0;
    }

    li {
        border-bottom: solid 0 transparent;
        text-decoration: none;
        
        a {
            transition: 0.25s;
            text-decoration: none;
            padding: 10px 0;
            display: block;
            color: ${colors.primary};
            font-family: centrano2-book;
            font-size: 0.875em;
            position: relative;
            min-width: 150px;
            
            &:before{
                content: "";
                display: block;
                background: ${colors.UILightStackSecondary};
                width: calc(100% + 16px);
                height: 100%;
                border-radius: 6px;
                position: absolute;
                z-index: -1;
                top:0;
                left: -8px;
                opacity: 0;
                transition: 0.25s;
            }

            &:hover {
                &:before{
                    opacity: 1;
                }
            }



        }

        &:hover {
            border-bottom: solid 0 transparent;
        }

    }
`;

export const MobileBurgerMenu = styled.div`
    width: 18px;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
        width: 30px;
    }

    height: 20px;
    position: relative;
    margin: 24px 0;

    span {
        left: 0;
        width: 100%;
        height: 2px;
        background: ${colors.primary};
        display: block;
        position: absolute;
        border-radius: 4px;
        transition: 0.35s;
        transform-origin: center center;

        &:nth-of-type(1) {
            top: 0;
        }

        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
            top: calc(50% - 1px);
        }

        &:nth-of-type(3) {
            bottom: 0;
        }
    }

    &.open-menu {
        span {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: 0%;
                left: 50%;
            }

            &:nth-of-type(5) {
                transform: rotate(45deg);
            }

            &:nth-of-type(4) {
                transform: rotate(-45deg);
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        display: none;
    }

`;

export const TabletProductsMenu = styled.div`
    display: none;
    border-top: solid 1px ${colors.UILightBordersTertiary};

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        height: 230px;
    }

    &.open-menu {
        display: block;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: space-between;
        gap: 10px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            gap: 30px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        & > div.sub-menu {
            width: auto;
            padding-right: 15px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                width: 30%;
                padding-right: 30px;
            }

            @media (min-width: ${mediaQueriesSizes.xl}px) {
                width: 20%;
            }

            border-right: solid 1px ${colors.UILightBordersTertiary};

            &:last-of-type {
                border-right: none;
                padding-right: 0;
            }
            
            &.xl-up{
                display: none;
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    display: block;
                }
            }
            
            .large-only{
                margin-top: 32px;
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    display: none;
                }
            }
            

        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 0.75em;
            line-height: 1.416;
            text-transform: uppercase;
            letter-spacing: 0.06em;
            /* UI-Light/Tertiary */
            color: ${colors.UILightTertiary};
            margin-bottom: 12px;
        }

        a {
            border-bottom: solid 1px ${colors.UILightStackSecondary};
            padding: 8px 0 6px;
            position: relative;
            display: inline-block;

            .Component-ProductLogo {
                display: inline-block;
            }

            &:before {
                content: "";
                display: block;
                background: ${colors.UILightStackSecondary};
                width: calc(100% + 16px);
                height: 100%;
                border-radius: 6px;
                position: absolute;
                z-index: -1;
                top: -2px;
                left: -8px;
                opacity: 0;
                transition: 0.25s;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }


            img {
                height: 22px;
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    height: 18px;
                }
                @media (min-width: ${mediaQueriesSizes.xxl}px) {
                    height: 22px;
                }
            }

            &:last-of-type {
                border-bottom: none;
            }
        }

        ul {
            padding: 0;

            li {
                border-bottom: solid 0 transparent;
                text-decoration: none;

                a {
                    transition: 0.25s;
                    text-decoration: none;
                    padding: 8px 0;
                    color: ${colors.primary};
                    font-family: centrano2-book;
                    font-size: 0.875em;
                    @media (min-width: ${mediaQueriesSizes.xl}px) {
                        font-size: 0.75em;
                    }
                    @media (min-width: ${mediaQueriesSizes.xxl}px) {
                        font-size: 0.875em;
                    }
                    
                    
                }

                &:hover {
                    border-bottom: solid 0 transparent;
                }

                ul {
                    padding: 0;
                    width: 100%;
                    position: relative;

                    li {
                        padding: 0;
                        padding-left: 16px;
                        border-bottom: none;
                        position: relative;

                        a {
                            display: inline-block;
                            text-decoration: none;
                            padding: 10px 0 12px;
                            &:before{
                                top:0;
                            }
                            
                        }

                        &:before {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background: ${colors.UILightTertiary};
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 12px;
                            @media (min-width: ${mediaQueriesSizes.xl}px) {
                                height: 12px;
                            }
                            @media (min-width: ${mediaQueriesSizes.xxl}px) {
                                height: 14px;
                            }

                        }

                        &:hover {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
`;

export const MobileMenuWrapper = styled.div`
    display: none;
    height: calc(100vh - 68px);
    overflow-y: auto;

    .container {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    &.open-menu {
        display: block;
    }

    h3 {
        display: none;
    }

    ul {
        border-top: solid 1px ${colors.UILightBordersSecondary};
        width: 100%;

        li {
            text-decoration: none;
            align-items: center;
            color: ${colors.primary};
            font-size: 1em;
            font-family: centrano2-book;
            position: relative;
            overflow-x: hidden;

            svg {
                color: ${colors.teal};
                font-size: 0.7em;
                position: absolute;
                top: 26px;
                right: 32px;
            }

            &.open {
                svg {
                    transform: rotate(180deg);
                }

                span {
                    font-family: centrano2-medium;
                }
            }

            a, span {
                width: 100%;
                color: ${colors.primary};
                display: inline-block;
                text-decoration: none;
                margin: 0 auto;
                padding: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 0.8em;
                line-height: 120%;
                font-feature-settings: 'tnum' on, 'lnum' on;
                border-bottom: solid 1px ${colors.UILightBordersSecondary};
                color: ${colors.primary};

                @media (min-width: ${mediaQueriesSizes.md}px) {
                    border-bottom: none;
                }
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    font-size: 1.125em;
                }
            }

            ul.products-menu {
                display: none;
                border-top: 0;

                a {
                    padding: 12px 0 12px 32px;

                    .Component-ProductLogo {
                        height: 24px;
                    }

                    img {
                        height: 24px;
                    }
                }

                &.open-menu {
                    display: block;
                }

            }

            ul.resources-menu,
            ul.news-menu,
            ul.resources,
            ul.industries-menu,
            ul.solutions-menu {
                display: none;
                border-top: 0;

                &.open-menu {
                    display: block;
                }

                ul, li {
                    padding: 0;
                }

                li {
                    a {
                        padding: 20px 0px 20px 60px;
                    }

                    ul {
                        border-top: 0;

                        li {
                            a {
                                padding: 20px 0px 20px 90px;
                            }
                        }
                    }


                    a {
                        width: 100%;
                    }
                }
            }


            &:hover {
                .solutions {
                    display: block;
                }
            }
        }
    }

    .menu-buttons {
        padding: 6px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }


    @media (min-width: ${mediaQueriesSizes.md}px) {

        height: auto;

        ul.products-menu,
        ul.solutions-menu,
        ul.industries-menu,
        ul.news-menu,
        .additional-btn {
            display: none !important;
        }

        ul {
            display: flex;
            justify-content: space-between;

            li {
                color: white;
                padding: 0;


                span {
                    svg {
                        top: 24px;
                        right: 0;
                    }
                }

                a {
                    //color: white;
                }
            }
        }

        .menu-buttons {
            display: none;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        display: none !important;
    }

`;

export const LanguageDropdown = styled.div`

    display: none;

    @media (min-width: ${mediaQueriesSizes.md}px) {
        display: flex;
        align-items: center;
    }

    @media (max-width: ${mediaQueriesSizes.lg}px) {
        .languages.open-menu {
            display: flex;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
    
        position: relative;
        
        &:after{
            content: "";
            width: 0%;
            position: absolute;
            bottom: 0;
            height: 2px;
            background: ${colors.primary};
            transition: 0.25s;
        }
        
        &:hover{
            &:after{
                width: 100%;
            }
        }
        
        .languages {
            display: flex !important;
            opacity: 0.25;
            visibility: hidden;
            transition: opacity 0.2s, visibility 0s 0ms;
            transition-delay: 1s;
        }

        .languages:hover {
            transition-delay: 0s; /* Remove the delay on hover */
        }

        &:hover {

            .languages {
                display: flex;
                opacity: 1;
                visibility: visible;
                transition-delay: 0s; /* Remove the delay on hover for .languages */
            }
        }


    }


    @media (min-width: ${mediaQueriesSizes.xl}px) {
        display: flex;
        align-items: center;
    }

`

export const LangSelector = styled.div`
    position: absolute;
    top: 100%;
    background: ${BGColour};
    flex-direction: column;
    align-items: flex-start;
    border: solid 1px ${colors.UILightTertiary};
    padding: 16px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 30px;
        a {
            padding: 6px;
        }
    }

    a {
        display: flex;
        align-items: center;
        padding: 10px;
        text-decoration: none;
        color: ${colors.primary};
        text-transform: capitalize;

        span {
            text-decoration: underline;
            margin-left: 4px;
        }

        img {
            display: block;
            width: 16px;
        }
    }

`;

export const LangBtn = styled.div`
    border: solid 1px ${colors.border};
    height: 48px;
    text-transform: capitalize;
    cursor: pointer;
    display: flex;
    padding: 0 16px;
    align-items: center;
    border-radius: 6px;
    transition: 0.25s;

    &:hover {
        background: ${colors.UILightStackSecondary};
    }

    svg {
        font-size: 0.75em;
    }

    img.primary {
        width: 16px;
        margin-right: 6px;
    }

    img.flag {
        width: 18px;
        margin-right: 8px;
    }

    &.mobile-lang-btn {
        margin-bottom: 16px;

        &.phone {
            img {
                margin-right: 8px;
            }
        }
    }

    span {
        color: ${colors.primary};
    }

    a {
        img {
            display: inline-block;
            margin-right: 6px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
        }
    }


    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 0 8px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 0 16px;
    }

`;