import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import moment from 'moment/moment';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundBlue, BackgroundHalfGrey, BackgroundGradientBlue } from '../GlobalStyles';

import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { IntroBlock } from '../components/V2/IntroBlock/IntroBlock';
import { SplitColumnExtraText } from '../components/V2/SplitColumnExtraText/SplitColumn';
import { InnovationIllustrationBlock } from '../components/V2/InnovationIllustrationBlock/InnovationIllustrationBlock';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { Grid } from '../components/V2/Grid/Grid';
import { StatisticsHomepageBlock } from '../components/V2/StatisticsHomepageBlock/StatisticsHomepageBlock';
import { EventCTA } from '../components/V2/Events/EventCTA/EventCTA';
import { LargeHeroABTest } from '../components/V2/LargeHero/LargeHero-ABTest';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

interface Props {
  Lang?: string;
  Content: object;
  Context: object;
}

const HomePageJSON = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Oqton',
  url: 'https://oqton.com/',
  logo: 'https://oqton.com/assets/images/DarkOqton.svg',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '1-803-981-6716',
      contactType: 'sales',
      areaServed: 'US',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+441442279875',
      contactType: 'sales',
      areaServed: 'GB',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+4961053248122',
      contactType: 'sales',
      areaServed: 'DE',
      availableLanguage: 'German'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+39800035847',
      contactType: 'sales',
      areaServed: 'IT',
      availableLanguage: 'Italian'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+33805103572',
      contactType: 'sales',
      areaServed: 'FR',
      availableLanguage: 'fr'
    }
  ],
  sameAs: [
    'https://twitter.com/oqton',
    'https://www.youtube.com/@Oqton',
    'https://www.linkedin.com/company/oqton/',
    'https://oqton.com/'
  ]
});

const HomepageTemplate = ({ Context, Content, Lang = 'en', ...props }: Props) => {
  const data = Content;

  const globalCopy = Context['globalData'];

  //**********************************************//
  //**********************************************//
  const vertical = '';
  //**********************************************//
  //**********************************************//

  const AnnoucementEndDate = moment('2023-12-12 17:00:00');
  const shouldDisplayAnnouncement = moment().isBefore(AnnoucementEndDate);

  let additionalClasses = '';
  if (shouldDisplayAnnouncement) {
    additionalClasses = 'additional-padding-top';
  }

  return (
    <>
      <HelmetContainer lang={Lang} title={data.helmet.title} description={data.helmet.description} />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header demoTitle={globalCopy.Buttons.RequestDemo} demoVertical={vertical} lang={Lang} />

          <LargeHeroABTest
            AdditionalClasses={'homepageHero'}
            AddSalesTel={true}
            BackgroundStyle={'cover'}
            Copy={data.content.hero.copy}
            Country={Lang}
            SecondaryLogo={{
              Logo: 'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905',
              Alt: 'Capterra Logo'
            }}
            Button={{
              copy: globalCopy.Buttons.ContactUs,
              link: '#contact-form',
              theme: 'accent-teal'
            }}
            Background={'/assets/images/homepage-v2/homepage-hero.webp'}
          />

          <BackgroundBlue>
            <LogoGridV2
              Title={data.content.customers.title}
              Logos={[
                {
                  src: '/assets/assets/images/logos/cummins-light.svg',
                  alt: 'Cummins'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/baker-hughes.png',
                  alt: 'Baker Hughes'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/addman.png',
                  alt: 'Addman Engineering'
                },
                {
                  src: '/assets/images/additive-manufacturing/company-logos/emerson.png',
                  alt: 'Emerson'
                }
              ]}
              AdditionalClasses={'theme-dark homepageLogos'}
            />
            <IntroBlock Title={data.content.intro_block.title} Copy={data.content.intro_block.copy} />

            <BackgroundHalfGrey>
              <div>
                <br />
                <br />
                <AnnoucementBar
                  Link={{
                    label: 'Know more',
                    link: '/posts/digital-workflows-in-orthotics-prosthetics-easier-than-ever-with-geomagic-freeform-2025-0-0/',
                    target: '_blank'
                  }}
                  Content={
                    '<h3>Geomagic Freeform - Release 2025</h3><p>Digital workflows in orthotics & prosthetics easier than ever with Geomagic Freeform 2025.0.0. Experience unparalleled ease and efficiency in digital design.</p>'
                  }
                />
              </div>
            </BackgroundHalfGrey>
          </BackgroundBlue>

          {data.content.split_blocks.map((block, index) => {
            let orientation = 'Right';
            if (index % 2) {
              orientation = 'Left';
            }
            return (
              <SplitColumnExtraText
                key={index}
                ImagePosition={orientation}
                Button={block.button}
                Copy={`<h3>${block.micro}</h3><h2>${block.title}</h2><h4>${block.subtitle}</h4><p>${block.copy}</p>`}
                Image={{
                  src: block.imageURL,
                  alt: ''
                }}
              />
            );
          })}

          <BackgroundGradientBlue>
            <div id={'industry-block'}>
              <InnovationIllustrationBlock
                Title={data.content.industry.title}
                Copy={data.content.industry.copy}
                Items={data.content.industry.items}
              />
            </div>

            <BackgroundHalfGrey>
              <div id={'additive-manufacturing-highlight-block'}>
                <HighlightBlock
                  Theme={'teal-dark'}
                  ImageType={'HalfBackground'}
                  MicroTitle={data.content.highlightBlock.microtitle}
                  Button={{
                    ModelTitle: globalCopy.Buttons.RequestDemo,
                    ModelVertical: vertical,
                    copy: globalCopy.Buttons.RequestDemo,
                    OpenDemoModel: true
                  }}
                  Image={{
                    url: '/assets/images/homepage-v2/homepage-highlight-block.png'
                  }}
                  AdditionalClass={'background-white homepage-feature'}
                  Title={data.content.highlightBlock.title}
                />
              </div>
            </BackgroundHalfGrey>
          </BackgroundGradientBlue>

          <div id={'manufacturing-insights-grid'}>
            <Grid
              ShowDivider={false}
              TitleAlignment="left"
              Theme={'teal'}
              Title={data.content.Grid2.title}
              additionalClasses={'flexible homepage-grid'}
              Items={data.content.Grid2.Items}
            />
          </div>

          <StatisticsHomepageBlock
            Lang={Lang}
            Block2={data.content.Statistics.block_2}
            Copy={data.content.Statistics.title}
            Items={data.content.Statistics.Items}
          />
        </BackgroundGrey>

        <Footer lang={Lang} Lang={Lang} />
      </PageContainer>
    </>
  );
};

export default HomepageTemplate;
