import React from 'react';

import { createMarkUp } from '../helpers';
import GlobalStyle from '../../../GlobalStyles';
import { CustomerStorySpotlightStyles, TitleContainer, CardContainer, Card } from './styles.jsx';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title: string;
  link?: object;
  items: object;
}

export const CustomerStorySpotlight = ({ Title, link, items, ...props }: Props) => {
  return (
    <CustomerStorySpotlightStyles>
      <div className={'container'}>
        <TitleContainer>
          <div>
            <h2>{Title}</h2>
            <hr className={'divider'} />
          </div>
          <ButtonComponent Theme={'stripped'} Link={link.url} Label={link.label} />
        </TitleContainer>

        <CardContainer>
          {items &&
            items.map((item, index) => (
              <Card key={index}>
                <div className={'img-wrap'}>
                  <img className={'card-img'} alt={item.img.alt} src={item.img.url} />
                  {item.author.img && <img className={'author-img'} alt={item.author.name} src={item.author.img} />}
                </div>
                <div className={'text-wrap'}>
                  <p>“{item.copy}”</p>
                  <h5 dangerouslySetInnerHTML={createMarkUp(item.author.name)}></h5>
                  <h6>{item.author.jobtitle}</h6>
                </div>
                <div className={'button-wrap'}>
                  <ButtonComponent
                    Theme={'stripped'}
                    Link={item.link.url}
                    Label={item.link.label}
                    Icon={'arrow-right'}
                  />
                </div>
              </Card>
            ))}
        </CardContainer>
      </div>
    </CustomerStorySpotlightStyles>
  );
};
