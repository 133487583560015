import React from 'react';

import { Header } from '../../../components/layouts/HeaderComponent';
import { Footer } from '../../../components/layouts/Footer';

import { PageContainer } from '../../../components/containers/PageContainer';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { LargeHero } from '../../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundWhite } from '../../../GlobalStyles';
import { SplitColumn } from '../../../components/V2/SplitColumn/SplitColumn';
import { BasicTextGrid } from '../../../components/V2/BasicTextGrid/BasicTextGrid';
import { FormComponent } from '../../../components/V2/Form/Form';
import { Gallery } from '../../../components/V2/Gallery/Gallery';
import { ProductHighlight } from '../../../components/V2/ProductHighlightBlock/ProductHighlightBlock';
import { ArticleBlock } from '../../../components/V2/ArticleBlock/ArticleBlock';

interface Props {
  Lang?: string;
}

const Polymers = ({ pageContext, Lang = 'es', ...props }: Props) => {
  const globalCopy = pageContext.globalData;

  const data = require(`../../../../data/wordpress-data/polymers-es.json`);
  const pageVertical = 'NewForm-3dxpert';

  return (
    <>
      <HelmetContainer
        lang={Lang}
        title={data.acfFields.seo.page_title}
        description={data.acfFields.seo.page_meta_description}
      />

      <PageContainer>
        <BackgroundGrey>
          <Header demoVertical={pageVertical} lang={Lang} demoTitle={globalCopy.Buttons.RequestDemo} />

          <LargeHero
            BackgroundStyle={'center-right'}
            Background={data.acfFields.hero_.image}
            Copy={`<h1><b>${data.acfFields.hero_.title}</b></h1>${data.acfFields.hero_.copy}`}
            Country={Lang}
            AddSalesTel={true}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <SplitColumn
          AdditionalClass={'polymers-video'}
          Copy={`<h2>Descubra cómo realizar <b>la colocación 3D de más de 450 piezas de polímeros</b> para fabricación aditiva.</h2>`}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/X2UyZM3RLBBgbQaS47WwGY?'}
          Background={'3DXpert-Circles'}
          ImagePosition={'Left'}
        />

        <BackgroundWhite>
          <BasicTextGrid
            Items={data.acfFields.main_section.blocks}
            Title={data.acfFields.main_section.title}
            Copy={data.acfFields.main_section.copy}
          />
        </BackgroundWhite>

        <Gallery
          Title={'Katmanlı üretim ile oluşturulan polimer parçalar'}
          Images={[
            {
              src: '/assets/2024/polymers/3d-nesting.png',
              caption: '<span>Objeto 1.</span> Posicionamiento 3D'
            },
            {
              src: '/assets/2024/polymers/fixture.png',
              caption: '<span>Objeto 2.</span> Fijación'
            },
            {
              src: '/assets/2024/polymers/drone-structure.png',
              caption: '<span>Objeto 3.</span> Estructura de dron'
            }
          ]}
        />

        <div className={'polymer-form-container'}>
          <div className={'container '}>
            <h3>{data.acfFields.form.form_subtitle}</h3>
            <h2>{data.acfFields.form.form_title}</h2>
            <hr className={'divider teal'} />
            <FormComponent
              Lang={Lang}
              HiddenFields={['message', 'product_interest']}
              PrefilledFields={[{ field: 'product_interest', value: '3DXpert' }]}
            />
          </div>
        </div>

        <ProductHighlight
          Title={'Software profesional de fabricación aditiva\n'}
          Copy={
            'Estructura de dron, 3DXpert ofrece una solución integrada para la impresión 3D de piezas de metal y plástico de alta calidad.'
          }
          Product={'3dxpert'}
        />

        <ArticleBlock
          Theme={'basic'}
          Title={'Fabricación aditiva'}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
          Link={{
            copy: 'Todos los conocimientos',
            link: '/news/'
          }}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Polymers;
