import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';

import {
  DefaultTextGridStyles,
  DefaultItem,
  SecondaryGridStyles,
  SecondaryLayout_PrimaryItem,
  SecondaryLayout_Item
} from './styles.jsx';

interface Props {
  Title?: string;
  Description?: string;
  Items?: object;
  Layout?: 'default' | 'layout-2';
}

export const TextGrid = ({ Title, Items, Description, Layout = 'default', ...props }: Props) => {
  if (Layout === 'default') {
    return (
      <>
        <DefaultTextGridStyles {...props}>
          <div className={'container'}>
            <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
            <div className={'grid-wrapper'}>
              {Items.map((item, index) => (
                <DefaultItem key={index}>
                  <h4>{item.title}</h4>
                  <p dangerouslySetInnerHTML={createMarkUp(item.copy)}></p>
                </DefaultItem>
              ))}
            </div>
            <p className={'description'} dangerouslySetInnerHTML={createMarkUp(Description)}></p>
          </div>
        </DefaultTextGridStyles>
      </>
    );
  } else {
    return (
      <>
        <SecondaryGridStyles {...props}>
          <div className={'container'}>
            <div className={'grid-wrapper'}>
              <SecondaryLayout_PrimaryItem>
                <h2>{Title}</h2>
                <hr className={'divider large'} />
              </SecondaryLayout_PrimaryItem>

              {Items.map((item, index) => (
                <SecondaryLayout_Item key={index}>
                  {item.subtitle && <h6>{item.subtitle}</h6>}
                  {item.title && <h4>{item.title}</h4>}
                  {item.copy && <p dangerouslySetInnerHTML={createMarkUp(item.copy)}></p>}
                </SecondaryLayout_Item>
              ))}
            </div>
            <p className={'description'} dangerouslySetInnerHTML={createMarkUp(Description)}></p>
          </div>
        </SecondaryGridStyles>
      </>
    );
  }
};
