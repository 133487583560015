import React from 'react';

import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';

import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundBlue, BackgroundGradientPrimary, BackgroundHalfBlue } from '../../GlobalStyles';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { BoxImageGrid } from '../../components/V2/BoxImageGrid/BoxImageGrid';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { TextBlock } from '../../components/V2/TextBlock/TextBlock';
import { SplitColumnExtraText } from '../../components/V2/SplitColumnExtraText/SplitColumn';
import { Grid } from '../../components/V2/Grid/Grid';
import { HighlightBlock } from '../../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { AnnoucementBar } from '../../components/V2/AnnoucementBar/AnnoucementBar';
import { SkinnyCTA } from '../../components/V2024/SkinnyCTA/SkinnyCTA';
import { BakerHughesBenefits } from '../../components/V2024/BakerHughesBenefits/BakerHughesBenefits';

interface Props {
  Lang?: string;
}

const AdditiveTemplate = ({ Lang = 'en', ...props }: Props) => {
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const data = require(`../../../data/content/Pages/additive/build-quality/${Lang}.json`);
  const pageVertical = 'build-quality';

  return (
    <>
      <HelmetContainer currentPage={'build-quality'} lang={Lang} title={'Build Quality - Additive Manufacturing'} />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header demoVertical={pageVertical} lang={Lang} />

          <LargeHero
            BackgroundStyle={'cover'}
            Background={'/assets/images/additive-manufacturing/additive-manufacturing-hero.png'}
            Copy={data.content.hero.copy}
            Country={Lang}
            AddSalesTel={true}
            Button={{
              ModelVertical: pageVertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <BackgroundGradientPrimary>
          <BoxImageGrid id={'toolkit'} Theme={'dark'} Items={data.content.BoxImageGrid.Items} />
        </BackgroundGradientPrimary>

        <BackgroundGrey>
          <SplitColumnExtraText
            ImagePosition={'right'}
            Copy={data.content.SplitCol4.Copy}
            Image={{
              src: '/assets/2024/images/build-quality/build-quality-new-additive-quality-standards.png',
              alt: ''
            }}
            FootnoteText={data.content.SplitCol4.Footnote}
          />

          <BackgroundHalfBlue>
            <AnnoucementBar
              AdditionalClass={'bq-eos-announcement'}
              Content={
                '<p>“The new Oqton Build Quality suite has the potential to lead to a significant leap in terms of quality assessment of additively manufactured metal components. The ease of use and automatic reporting capabilities are a key step towards the end-to-end traceability and assessment of AM parts. EOS customers can now access AI capabilities within the Oqton Build Quality suite, thanks to seamless integration with EOS software and the close collaboration between Oqton as partner of the EOS Developer Network.”</p><h3><b>Rüdiger Herfrid,</b><br/>Product Manager Software, EOS Gmbh</h3>'
              }
              Image={'/assets/images/additive/companylogos/eos-logo-black.png'}
            />
          </BackgroundHalfBlue>

          <BackgroundBlue>
            <Grid
              TitleColour={'white'}
              Title={data.content.Grid.Title}
              ShowDivider={false}
              additionalClasses={'flexible'}
              Items={data.content.Grid.Items}
            />
          </BackgroundBlue>
        </BackgroundGrey>

        <TextBlock ShowHR={true} Title={data.content.TextBlock.Title} FullCopy={data.content.TextBlock.Copy} />

        <BackgroundGrey>
          <SplitColumn
            ImagePosition={'Right'}
            Copy={data.content.SplitCol1.Copy}
            MediaType={'Video'}
            Video={'https://play.vidyard.com/n9YGe2BTsfYfo4wJg7zPtg?'}
          />

          <SkinnyCTA
            size={'small'}
            content={data.content.HighlightFormnext.Title}
            cta={{
              copy: data.content.HighlightFormnext.cta.copy,
              theme: 'accent-teal',
              href: 'https://www.youtube.com/watch?time_continue=9&v=3kzQidCkuAE&embeds_referring_euri=https%3A%2F%2Foqton.com%2F&embeds_referring_origin=https%3A%2F%2Foqton.com&source_ve_path=MTM5MTE3LDM2ODQyLDIzODUx&feature=emb_title',
              target: '_blank'
            }}
          />

          <div className={'spacing-xl'}></div>

          <BakerHughesBenefits content={data.content.BuildMonitoring} />

          <div className={'spacing-xl'}></div>

          <SkinnyCTA
            size={'small'}
            content={data.content.HighlightOnPrem.Title}
            cta={{
              copy: data.content.HighlightOnPrem.cta.copy,
              theme: 'accent-teal',
              href: 'https://go.oqton.com/build-monitoring-on-prem',
              target: '_blank'
            }}
          />

          <HighlightBlock
            AdditionalClass={'background-white text-primary text-limit-width'}
            Theme={'teal-dark'}
            ImageType={'Background'}
            Subtitle={data.content.HighlightBlock2.subtitle}
            Title={data.content.HighlightBlock2.title}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/additive-manufacturing/factory-floor-cta-bg.png'
            }}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: pageVertical,
              copy: data.content.HighlightBlock2.buttonText,
              OpenDemoModel: true
            }}
          />

          <SplitColumn
            ImageBackground={false}
            ImageOverlap={false}
            ImagePosition={'Right'}
            Copy={data.content.SplitCol5.Copy}
            MediaType={'Image'}
            Image={{
              src: '/assets/2024/build-quality/magazine1.png'
            }}
            Button={{
              target: '_blank',
              theme: 'stripped',
              copy: 'Link to article',
              link: 'https://bit.ly/3TQVTbM'
            }}
          />
        </BackgroundGrey>

        <ArticleBlock
          Title={data.content.Articles.Title}
          Layout={'layout-3'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default AdditiveTemplate;
