import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../../GlobalStyles";

export const NewsSubNavigationStyles = styled.div`

    display: none;
    padding: 16px 0;
    flex-direction: row;

    ul {
        border-bottom: solid 1px ${colors.UILightBordersSecondary};

        li {
            display: inline-block;
            margin: 0 0 12px;
            font-family: centrano2-medium;
            font-size: 16px;
            line-height: 1;
            color: ${colors.primary};

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            a {
                text-decoration: none;
                padding: 12px 8px;
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        display: flex;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 64px 0;
        ul {
            li {
                margin: 0 8px 24px;
            }
        }
    }



`;