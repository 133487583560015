import React from 'react';

import AerospaceTemplate from './../../templates/AerospaceTemplate';

const LotnictwoIKosmonautyka = () => {
  const Lang = 'pl';

  return <AerospaceTemplate Lang={Lang} />;
};

export default LotnictwoIKosmonautyka;
