import React from 'react';

import GlobalStyle from '../../../../GlobalStyles';

import { HighlightBlockStyles, Inner, ImgWrap, CopyWrap, ImgWrapSplit, BackgroundImage } from './styles.jsx';
import { createMarkUp } from '../../helpers';

interface Props {
  Title?: string;
  Copy?: string;
  Image?: {
    className: '';
    url: '';
    title: '';
    alt: '';
  };
  Theme?:
    | 'accent-red'
    | 'accent-green'
    | '3dxpert'
    | 'Primary'
    | 'geomagicSolidWorks'
    | 'teal'
    | 'manufacturing-os'
    | 'dark-teal';
  Size?: 'default' | 'extraText';
  ImageType?: 'Separate' | 'Background' | 'Split';
  AdditionalClass?: string;
}

export const HighlightBlockRightImage = ({
  Title = 'Alpha Bravo Charlie',
  Copy = '',
  AdditionalClass = '',
  Size = 'default',
  ImageType = 'Separate',
  Image = {
    className: '',
    url: '',
    title: '',
    alt: ''
  },
  Theme = 'dark-teal',
  ...props
}: Props) => {
  return (
    <HighlightBlockStyles
      className={`${AdditionalClass}`}
      {...props}
      style={ImageType === 'Background' ? { backgroundImage: `url(${Image.url})` } : {}}
    >
      <div className={'container'}>
        <Inner
          className={`${AdditionalClass} theme-${Theme} ${ImageType === 'Background' ? 'background-image' : ''} ${
            ImageType === 'Split' ? 'flex-center' : ''
          }`}
        >
          <CopyWrap className={`${AdditionalClass} size-${Size} theme-${Theme} ImageType-${ImageType}`}>
            {Title && <h3 dangerouslySetInnerHTML={createMarkUp(Title)}></h3>}
            {Copy && <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}
          </CopyWrap>
          {ImageType === 'Separate' && (
            <ImgWrap className={Image.className + ' size-' + Size}>
              <img src={Image.url} alt={Image.alt} title={Image.title} />
            </ImgWrap>
          )}
          {ImageType === 'Split' && (
            <ImgWrapSplit>
              <img src={Image.url} alt={Image.alt} title={Image.title} />
            </ImgWrapSplit>
          )}
        </Inner>
      </div>
    </HighlightBlockStyles>
  );
};
