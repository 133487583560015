import React from 'react';
import { colorsRD } from '../../components/foundations';
import { CustomerStorySpotlight } from '../../components/layouts/CustomerStorySpotlight';
import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { ListContainer } from '../../components/pageComponents/Styles/style';
import { Resources } from '../../components/layouts/Resources';
import { PrinterOverview } from '../../components/pageComponents/PrinterOverview';
import { Demo } from '../../components/layouts/Demo';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { Disclaimer } from '../../components/layouts/PageContent/PageContentStyles';
import { createMarkUp } from '../../components/V2/helpers';
import { PageContainer } from '../../components/containers/PageContainer';

const D2P = () => {
  const Lang = 'it';
  const data = require(`../../../data/content/Pages/products/d2p/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <HelmetContainer
        lang={Lang}
        image="/assets/images/pages/d2p/hero.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
        currentPage={'d2p'}
      />
      <PageContainer lang={Lang}>
        <HeaderBackground>
          <Header lang={Lang} />
          <Hero
            lang={Lang}
            title={data.content.hero.title}
            description={data.content.hero.desc}
            subtitle={<HeaderSubtitle icon="D2P" text="Dicom to print" />}
            image={{ src: '/assets/images/pages/d2p/hero.png', alt: 'D2P Hero' }}
            buttons={[
              { text: globalCopy.Buttons.RequestFreeTrial, vertical: 'd2p' }
              // { text: globalCopy.Buttons.ContactUs, vertical: 'buyD2P', color: colorsRD.primary }
            ]}
            phone
          />
        </HeaderBackground>
        <MediaComponent
          articles={[
            {
              title: data.content.Media.title,
              content: (
                <React.Fragment>
                  <p>{data.content.Media.copy1}</p>
                  <br />
                  <p>{data.content.Media.copy2}</p>
                  <br />
                  <p>{data.content.Media.copy3}</p>
                </React.Fragment>
              ),
              image: { src: '/assets/images/pages/d2p/media_1.png', alt: 'Placeholder', imagePos: 'right' }
            },
            {
              content: (
                <React.Fragment>
                  <p dangerouslySetInnerHTML={createMarkUp(data.content.Media2.content)}></p>
                </React.Fragment>
              ),
              image: { src: '/assets/images/pages/d2p/media_2.png', alt: 'Placeholder', imagePos: 'left' }
            }
          ]}
        />
        <MediaComponent
          inverted
          articles={[
            {
              title: data.content.Media3.title,
              content: <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media3.list)}></ListContainer>,
              image: { src: '/assets/images/pages/d2p/media_3.png', alt: 'Placeholder', imagePos: 'left' }
            }
          ]}
        />
        <Resources
          title={data.content.Resources.title}
          description={data.content.Resources.description}
          resources={[
            {
              title: data.content.Resources.res_1.title,
              label: data.content.Resources.res_1.label,
              withBackground: true,
              link: '/assets/images/pages/d2p/d2p-brochure-22V1.pdf'
            },
            {
              title: data.content.Resources.res_2.title,
              label: data.content.Resources.res_2.label,
              link: '/assets/images/pages/d2p/3d-systems-d2p-2021-whatsnew-usen-letter-2021-03-12-h-web.pdf'
            },
            {
              title: data.content.Resources.res_3.title,
              label: data.content.Resources.res_3.label,
              link: '/assets/images/pages/d2p/SPEC-0419_C_D2P_System Requirements.pdf'
            }
          ]}
        />
        <PrinterOverview lang={Lang} />
        <CustomerStorySpotlight
          title={data.content.CustomerStorySpotlight.title}
          cards={[
            {
              smallText: data.content.CustomerStorySpotlight.smallText,
              customerName: data.content.CustomerStorySpotlight.customerName,
              image: {
                src: '/assets/images/pages/d2p/customer_story_1.png',
                alt: 'placeholder',
                position: 'bottom'
              },
              hideLink: true
            }
          ]}
        />
        <Demo
          title={data.content.Demo.title}
          description={data.content.Demo.description}
          buttonText={data.content.Demo.buttonText}
        />
        <MediaComponent
          articles={[
            {
              content: <Disclaimer>{data.content.Disclaimer.content}</Disclaimer>
            }
          ]}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default D2P;
