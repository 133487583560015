import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { createMarkUp } from '../helpers';

import { ClientSpotlightStyles, ItemWrapper, Item } from './styles.jsx';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title?: string;
  Items?: object;
}

export const ClientSpotlight = ({ Title = '', Items, ...props }: Props) => {
  return (
    <ClientSpotlightStyles className={'Component-ClientSpotlight'} {...props}>
      <div className={'container'}>
        <h2>{Title}</h2>
        <div className="title-underline "></div>

        <ItemWrapper>
          {Items &&
            Items.map((item, index) => (
              <Item key={index}>
                {item.Logo && (
                  <>
                    <div className="image-wrap">
                      <img src={item.Logo} alt={item.LogoAlt} />
                    </div>
                    <hr />
                  </>
                )}
                {!item.Logo && (
                  <>
                    <div className="image-wrap">
                      <img src={''} alt={''} />
                    </div>
                    <hr />
                  </>
                )}
                <p dangerouslySetInnerHTML={createMarkUp(item.Copy)}></p>
                <br />
                <p className={'quoted_person'}>{item.Person}</p>

                {item.Button && (
                  <ButtonComponent Theme={'stripped'} Label={item.Button.Label} Link={item.Button.Href} />
                )}
              </Item>
            ))}
        </ItemWrapper>
      </div>
    </ClientSpotlightStyles>
  );
};
