import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';
import { ResourceDownloadStyles, DownloadBlock, ItemWrapper } from './styles.jsx';

import DownloadIcon from './download.svg';

interface Props {
  id?: string;
  Title?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const ResourceDownloads = ({ id = '', Title = '', Items = [], Theme = 'light', ...props }: Props) => {
  return (
    <ResourceDownloadStyles id={id} {...props}>
      <div className={'container'}>
        <div className={'inner'}>
          <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
          <ItemWrapper className={'item-count-' + Items.length}>
            {Items.map((item, index) => (
              <DownloadBlock key={index} href={item.link} className={'theme-' + Theme} target={'_blank'}>
                <div className={'card-inner'}>
                  <img alt={''} src={item.fileIcon} />
                  <p>{item.title}</p>
                </div>
                <img alt={''} src={DownloadIcon} />
              </DownloadBlock>
            ))}
          </ItemWrapper>
        </div>
      </div>
    </ResourceDownloadStyles>
  );
};
