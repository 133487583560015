import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const TextBlockStyles = styled.div`

    padding: 32px 0;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 48px 0;
    }

    
    h4 {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: ${colors.teal};
        margin-bottom: 16px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 18px;
        }
    }

    h2 {
        font-family: centrano2-medium;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        text-align: left;
        color: ${colors.primary};
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 40px;
        }
    }

    h3 {
        font-family: centrano2-book;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        text-align: left;
        color: ${colors.primary};
        margin: 1em 0;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 28px;
        }
    }

    hr.divider {
        margin: 16px 0;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            margin: 24px 0;
        }
    }

    p {
        font-family: centrano2-book;
        font-size: 22px;
        line-height: 1.45;
        letter-spacing: 0.02em;
        margin-top: 16px;

        img {
            display: block;
            width: 100%;
            margin: 16px auto;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin: 42px auto;
                width: 80%;
            }
        }
    }

    ul.basic {
        list-style: circle;
        margin: 16px 0;

        li {
            margin-left: 32px;
            color: ${colors.light};
            font-size: 18px;
            line-height: 1.45;
            font-family: centrano2-book;

            &::marker {
                color: ${colors.light};
            }
        }
    }

    ol {
        margin-left: 18px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            margin-left: 36px;
        }

        li {
            color: ${colors.light};
            font-family: centrano2-book;
            margin-bottom: 42px;
            line-height: 26px;
            font-size: 18px;

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: 22px;
                padding-left: 16px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            b {
                font-family: centrano2;
                font-weight: 600;
            }

            &::marker {
                color: ${colors.highlight};
            }

            ul {
                margin-top: 16px;
                margin-bottom: 0;
                margin-left: 24px;

                li {
                    margin-bottom: 0;
                    list-style: none;

                    &::marker {
                        display: none;
                    }

                    :before {
                        content: "-";
                        color: ${colors.highlight};
                        display: inline-block;
                        width: 1em; /* Adjust the width as needed */
                        margin-left: -1em; /* Adjust the margin as needed */
                    }
                }
            }
        }
    }

`;
